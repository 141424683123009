(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorPhotoController', EditorPhotoController);

    EditorPhotoController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder','MessageNotification', 'Product', 'SalesOrder',
        'ProductLine', 'Iphone', 'CustomerUploadAble','Customer', 'StoreConfig','MessageService','DateUtils', '$websocket'];

    function EditorPhotoController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder,MessageNotification, Product, SalesOrder,
                                   ProductLine, Iphone, CustomerUploadAble,Customer,StoreConfig,MessageService,DateUtils, $websocket) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $scope.dHeight = document.documentElement.clientHeight;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});
        var vm = this;
        var isWx = false;
        var browser = CommonService.browserVersions();
        if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
            var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //在微信中打开
                isWx = true;
            }else if(browser.versions.android){
                //是否在安卓浏览器打开
                isWx = false;
            }
            if (ua.match(/WeiBo/i) == "weibo") {
                //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) == "qq") {
                //在QQ空间打开
            }
            if (browser.versions.ios) {
                //是否在IOS浏览器打开
                isWx = false;
            }
        } else {
            //否则就是PC浏览器打开
        }
        vm.isIPhone6 = isIPhone6();
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.salesOrderId = $stateParams.soid ? $stateParams.soid : null;
        vm.productLineId = $stateParams.plid ? $stateParams.plid : null;
        vm.aid = $stateParams.aid;
        vm.productLine = {};
        vm.article={};

        $scope.domains = Qinius.getDomain1();
        vm.tokenTime = 360000;
        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });
        vm.indexNum = 0;
        vm.maxId = null;
        vm.itemsPerPage = 40;
        vm.platformSetting = PlatformWechatSetting.get();
        vm.photoArray = [];
        vm.photoSelectAll=[];
        vm.isPhone = false;
        $scope.qiniuId = JSON.parse(localStorage.getItem("gallery")) != null? JSON.parse(localStorage.getItem("gallery")): [];
        $scope.status = localStorage.getItem('Production');
        vm.page1 = true;
        vm.page2 = false;
        vm.store = Store.get({id: vm.storeId});
        vm.customer = Customer.current();

        vm.isGuidePage = true;
        vm.isGuidePage1 =  false;
        vm.guideVersions = '1.0.0';
        vm.threeLoading = true;
        vm.threeLoadingText = '加载中...';
        if(localStorage.getItem("guide-editor-photo") == vm.guideVersions){
            vm.isGuidePage1 =  false;
        }else{
            vm.isGuidePage1 =  true;
            localStorage.setItem("guide-editor-photo", vm.guideVersions);
            vm.guidePage = 1;
        }
        vm.messageShow = true;
        //消息列表
        getMessage();
        function getMessage() {
            vm.messageList = "";
            getMessageCustom(vm, DateUtils, MessageNotification, function (res) {
                res.forEach(function (item, index) {
                    vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                })
                console.log(vm.messageShow,vm.messageList)
            })
        }
        // 成功多少张
        var indd = 0;
        var errorImageArr = [];
        var initValue = 0;

        //区别小程序、公众号
        localStorage.removeItem('flag');
        if (window.location.href.indexOf("?") > -1) {
            localStorage.setItem('themeFlag', 'two');
            // localStorage.removeItem('guide-step');
            var r = window.location.href.split('?')[1];
            var typeFlag = r.split('&')[0].split('=')[1];
            var authorize = r.split('&')[1].split('=')[1];
            var productionType = r.split('&')[2].split('=')[1];
            var formLibrary = r.split('&')[3].split('=')[1];
            var orderOrigin = null;
            if (r.split('&').length>=5) {
                orderOrigin = r.split('&')[4].split('=')[1];
            }

            orderOrigin = decodeURIComponent(orderOrigin);
            var distinguish = decodeURIComponent(typeFlag);
            var tempAuthorization = decodeURIComponent(authorize);
            var authorization = tempAuthorization.slice(7, tempAuthorization.length);
            var showFlag = decodeURIComponent(productionType);
            var editLibrary = decodeURIComponent(formLibrary);
            if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake' || distinguish == 'toutiaoApp' || distinguish == 'alipayApp' || distinguish == 'jdApp') {
                vm.uploadType = distinguish;
                localStorage.setItem('orderOrigin', orderOrigin);
                localStorage.setItem('distinguish', distinguish);
                $localStorage.authenticationToken = authorization;
                var workSave = JSON.parse(localStorage.getItem("work-save"));
                var pid = editLibrary.split(',')[0];
                var vid = editLibrary.split(',')[1];
                var xmlId = editLibrary.split(',')[2];
                if (workSave == null) {
                    var obj = {
                        pid: pid,
                        vid: vid,
                        xmlId:xmlId,
                        date: new Date().getTime()
                    };
                    localStorage.setItem("work-save", JSON.stringify(obj));
                    localStorage.removeItem("pages");
                    localStorage.removeItem("gallery");
                    localStorage.removeItem("galleryCopy");
                } else {
                    var currentDate = new Date().getTime();
                    var flag = (currentDate - workSave.date) > 3 * 3600 * 1000 ? true : false;
                    if (workSave.pid != pid || workSave.vid != vid || flag) {
                        localStorage.removeItem("pages");
                        localStorage.removeItem("gallery");
                        localStorage.removeItem("galleryCopy");
                        var obj = {
                            pid: pid,
                            vid: vid,
                            xmlId:xmlId,
                            date: new Date().getTime()
                        };
                        localStorage.setItem("work-save", JSON.stringify(obj));
                    }
                }
            }
            vm.target=getQueryVariable('target');
            localStorage.setItem('target', vm.target);
            vm.flag=getQueryVariable('flag');
            localStorage.setItem('flag', vm.flag);
        }
        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };
        /**
         * wechat api
         */
        if (wx) {
            var url = "";
            if (!!window.location.hash) {
                url = window.location.href.slice(0, -window.location.hash.length);
            }else{
                url =window.location.href;
            }
            WxJS.get({url: url}, function (config) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: config.appId, // 必填，公众号的唯一标识
                    timestamp: config.timestamp, // 必填，生成签名的时间戳
                    nonceStr: config.nonceStr, // 必填，生成签名的随机串
                    signature: config.signature,// 必填，签名，见附录1
                    jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(function () {

                    wx.hideMenuItems({
                        menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                    });
                });
                wx.error(function (res) {
                });
            }, function () {
            });
        }

        $q.all([$scope.domains.$promise, vm.store.$promise, vm.customer.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            if(!vm.userId){
                vm.userId = vm.customer.id
            }
            setTimeout(function fn() {
                Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            //获取照片旋转角度使用

            //判断是ios or Android
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if(vm.uploadType=='alipayApp' || vm.uploadType=='toutiaoApp'){
                $scope.agentIos = true;
            }else if(!isWx){
                $scope.agentIos = false;
            } else if (isAndroid == true && isiOS == false) {
                $scope.agentIos = true;
            } else if (isAndroid == false && isiOS == true) {
                $scope.agentIos = false;
            }
            vm.isIPhoneX = Iphone;
            if(!vm.aid){
                if(vm.store.orderWay == 'orderThenEditor'){
                    if(vm.productLineId){
                        ProductLine.get({id: vm.productLineId}, function (res) {
                            vm.productLine = res;
                            init1()
                        })
                    }
                }else{
                    init()
                }
            }else{
                if(vm.store.orderWay == 'orderThenEditor'){
                    if(vm.productLineId){
                        ProductLine.get({id: vm.productLineId}, function (res) {
                            vm.productLine = res;
                            init2()
                        })
                    }else{
                        init2()
                    }
                }else{
                    init2()
                }
            }
            $timeout(function () {
                qInit();
            })

            websocket();
        });

        $scope.uploadPhoto = function () {
            uploadVerify(vm.platformSetting, CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                if($scope.agentIos){
                    $rootScope.$broadcast('clickAndroidUpload',true);
                }else{
                    $timeout(function () {
                        $("#uploadImageMutipleId").trigger("click");
                    })
                }
            },function(error){
                if(error === "networkError"){
                    MessageService.hint("网络错误，请切换网络重试");
                }
            })
        }
        vm.uploadPhotoItem=[];
        vm.today = "";
        $scope.$on('uploadStart',function(){
            var date = new Date();
            vm.today = date.getFullYear() + '年'+ (date.getMonth() + 1) + '月' + date.getDate() + '日';
            console.log(vm.photoArray);
            if(vm.photoArray.length > 0 && vm.photoArray[0].createDate == vm.today){
                return;
            }
            //造一个空的今日title项
            vm.photoArray.unshift({
                createDate: vm.today,
                galleries:[]
            })

        })
        $scope.$on('uoloadFinished', function(event, args) {
            // if(args){
            //     updatePic();
            // }
        });
        $scope.$on('uoloadDuplicate', function(event, res) {
            updatePic();
        });
        // 上传图片后更新图片库/文件夹
        function updatePic() {
            vm.maxId = null;
            if (vm.folderOne && vm.folderOne.id) {
                // $scope.showFolderContent(vm.folderOne);
                vm.photoAll = [];
                loadAll(vm.folderOne.id);
            } else {
                vm.photoAll=[];
                vm.indexNum = 0;
                vm.photoCompleted = false;
                loadAll('isNull');
            }
        }

        $scope.galleryNavSel = function (item) {
            vm.currentNav = item;
            vm.indexNum = 0;
            vm.photoCompleted = false;
            vm.photoAll = [];
            vm.folderOne = null;
            vm.maxId = null;
            if(item == 'gallery'){
                loadAll('isNull')
            }
            if(item == 'folder'){
                loadFolders()
            }
        };
        // $(window).scroll(function () {
        //     //向下滑动加载照片
        //     if(vm.page2 || vm.page9){
        //         var scrollTop = $(window).scrollTop();
        //         var widH = $(window).height();
        //         var docH = $(document).height();
        //         if (scrollTop + widH == docH) {
        //             if(!vm.photoCompleted){
        //                 vm.indexNum++;
        //
        //                 if (vm.folderOne && vm.folderOne.id) {
        //                     loadAll(vm.folderOne.id);
        //                 } else {
        //                     loadAll('isNull');
        //                 }
        //             }
        //         }
        //     }
        // });

        function qInit(){
            $('.c-productThree2-head3-page2').on('scroll', function(event){
                var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                if(!vm.photoCompleted){
                    if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                        vm.indexNum++;
                        loadAll('isNull');
                    }
                }
            })
            $('.c-productThree2-head3-page9').on('scroll', function(event){
                var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                if(!vm.photoCompleted){
                    if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                        vm.indexNum++;
                        loadAll(vm.folderOne.id);
                    }
                }
                $scope.$digest();
            })
        }
        // 增加文件夹
        $scope.addFolder = function () {
            setTimeout(function(){
                $(".sweet-alert").addClass('whiteBg');
            })
            swal({
                title: "新建照片文件夹",
                text: "",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                animation: "slide-from-top",
                inputPlaceholder: "请输入文件夹名称"
            }, function (inputValue) {
                if (inputValue === false) {
                    $(".folder-footer").show();
                    return false;
                }
                if (inputValue === "") {
                    swal.showInputError("请输入名称!");
                    return false
                }
                if (inputValue.indexOf(" ") != -1) {
                    swal.showInputError("请不要输入空格!");
                    return false
                }
                Folder.save({
                    name: inputValue,
                    type: 'Gallery'
                }, function (resp) {
                    $scope.Folders.push(resp);
                    swal.close();
                    setTimeout(function(){
                        $(".sweet-alert").removeClass('whiteBg');
                    },200)
                }, function () {
                    swal.showInputError("请输入正确的文件名!");
                })
            });
        };
        //文件夹多选
        vm.isChoiceFolder = false;
        $scope.choiceFolder = function () {
            vm.isChoiceFolder = true;
        }
        $scope.cancelFolder = function () {
            vm.isChoiceFolder = false;
            vm.folderSelectData = "";
            $scope.Folders.forEach(function (data) {
                data.selected = false;
            })
        }
        vm.isEditFolderName = false;
        $scope.editFolder = function () {
            if(!vm.folderSelectData){
                threeInfo("请选择改名文件夹");
                return
            }
            vm.isEditFolderName = true;
        }
        $scope.editFolderNameHide = function () {
            vm.isEditFolderName = false;
        }
        $scope.editFolderNameSave = function () {
            vm.isEditFolderName = false;
            if (!vm.folderSelectData || !vm.folderSelectData.name) {
                threeInfo("请输入名称");
                return false
            }
            if (vm.folderSelectData.name.indexOf(" ") != -1) {
                threeInfo("请不要输入空格");
                return false
            }
            Folder.update(vm.folderSelectData, function (resp) {
                threeInfo("改名成功");
                $scope.cancelFolder();
                loadFolders();
                swal.close();
            }, function () {
                threeInfo("请输入正确的文件名");
            })
        }
        function loadFolders() {
            Folder.getAllByConditionAndQuantity({
                type:"Gallery"
            }, function (resp) {
                $scope.Folders = resp.message;
            })
        }
        vm.folderSelectData = "";
        $scope.folderSelect = function (item) {
            if(vm.isChoiceFolder){
                vm.folderSelectData = "";
                $scope.Folders.forEach(function (data) {
                    if(item.id == data.id){
                        data.selected = !data.selected ? true : false;
                        if(item.selected){
                            vm.folderSelectData =  angular.copy(item);
                        }
                    }else {
                        data.selected = false;
                    }
                })
            }else {
                vm.photoCompleted = false;
                vm.folderOne = item;
                vm.photoAll = [];
                vm.photoArray = [];
                vm.indexNum = 0;
                vm.maxId = null;
                loadAll(item.id)
                vm.page2 = false;
                vm.page9 = true;
            }
        }
        $scope.folderSave = function () {
            if (vm.pictureType == 'spread' && vm.photoSelectAll.length % 2 === 1){
                threeInfo("请选择偶数数量的照片");
                return
            }
            vm.page1 = true;
            vm.page2 = false;
            vm.page9 = false;
            vm.folderOne = null;
            for(var i=0; i<vm.photoSelectAll.length; i++){
                vm.photoSelectAll[i].seq = i+1;
            };
            abook(true);
        }

        function loadAll(folderId) {
            var items = [];
            if (folderId == 'isNull') {
                items.push({key: "folderId", op: "isNull", value: 1})
            }else{
                items.push({key: "folderId", op: "=",value: folderId});
            }
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            Gallery.getPage({
                items: items,
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: sorts()
            }, onSuccess, onError);
        }
        function sorts() {
            return ["id,desc"];
        }
        function onSuccess(res) {
            for(var i=0; i<res.length; i++){
                if(!res[i].cancelled){
                    res[i].identifier = $scope.domain+res[i].identifier;
                    vm.photoAll.push(res[i])
                }
            }
            if(res.length < vm.itemsPerPage){
                vm.photoCompleted = true;
            }
            if (vm.maxId == null && res.length > 0) {
                vm.maxId = res[0].id;
            }
            getPagePhoto();
        }
        function onError(data) {
            MessageService.hint("网络错误，请切换网络重试");
        }
        function getPagePhoto() {
            var timeList=[];
            vm.photoArray = [];
            for(var i=0; i<vm.photoAll.length; i++){
                var photoCreatedDate = new Date(vm.photoAll[i].createdDate);
                if (checkExist(timeList, photoCreatedDate)) {
                    timeList.push(photoCreatedDate);
                }
            }
            //将相同日期下的照片整合
            for (var m = 0; m < timeList.length; m++) {
                var tempObj = {};
                var tempItems = [];
                for (var j = 0; j < vm.photoAll.length; j++) {
                    var createdDate = new Date(vm.photoAll[j].createdDate);
                    if (timeList[m].getFullYear() == createdDate.getFullYear() &&
                        timeList[m].getMonth() == createdDate.getMonth() &&
                        timeList[m].getDate() == createdDate.getDate()) {
                        tempItems.push(vm.photoAll[j])
                    }
                }
                var time = timeList[m].getFullYear() + '年' + (timeList[m].getMonth() + 1) + '月' + timeList[m].getDate() + '日';
                tempObj = {"createDate": time, "galleries": tempItems};
                vm.photoArray.push(tempObj);
            }
            getPagePhotoSelected();
        }
        function getPagePhotoSelected() {
            vm.phoneAllSelected = true;
            for(var i=0; i<vm.photoArray.length; i++){
                vm.photoArray[i].selected = true;
                for(var j=0; j<vm.photoArray[i].galleries.length; j++){
                    vm.photoArray[i].galleries[j].selected = false;
                    for(var k=0; k<vm.photoSelectAll.length; k++){
                        if(vm.photoArray[i].galleries[j].id == vm.photoSelectAll[k].id){
                            vm.photoArray[i].galleries[j].selected = true;
                        }
                    }
                    if(!vm.photoArray[i].galleries[j].selected){
                        vm.photoArray[i].selected = false;
                        vm.phoneAllSelected = false;
                    }
                }
            }
            getFrequency();
        }
        // 判断是否重复
        function checkExist(list, obj) {
            if (list == null || list.length == 0) {
                return true;
            }
            for (var i = 0; i < list.length; i++) {
                if (list[i].getFullYear() == obj.getFullYear() &&
                    list[i].getMonth() == obj.getMonth() &&
                    list[i].getDate() == obj.getDate()) {
                    return false;
                }
            }
            return true;
        }
        //全选当前图片
        $scope.photoAllCb = function (item) {
            item.selected = !item.selected ? true : false;
            for(var i=0; i<item.galleries.length; i++){
                item.galleries[i].selected = item.selected;
                photoSelect(item.galleries[i])
            }

            selectAllBtnState()
        }
        //选中当前图片
        $scope.photoOneCb = function (item) {
            // if (!item.selected && vm.photoSelectAll.length >= vm.productActiveSku.packageQuantity) {
            //     threeInfo("数量已满"+vm.productActiveSku.packageQuantity+"张，无法继续添加");
            //     return
            // }
            item.selected = !item.selected ? true : false;
            photoSelect(item);
            selectAllBtnState()
        }
        //全选
        vm.phoneAllSelected = false;
        $scope.photoAllSelect = function () {
            vm.phoneAllSelected = !vm.phoneAllSelected;
            vm.photoArray.forEach(function (item) {
                item.selected = vm.phoneAllSelected;
                for(var i=0; i<item.galleries.length; i++){
                    item.galleries[i].selected = vm.phoneAllSelected;
                    photoSelect(item.galleries[i])
                }
            })
        }
        function photoSelect(item) {
            // 图片选中判断是否有图片没有再添加
            if (item.selected) {
                var isExist = false;
                for(var i=0; i<vm.photoSelectAll.length;i++){
                    var photoSelect = vm.photoSelectAll[i];
                    if (photoSelect.id == item.id) {
                        isExist=true;
                        break
                    }
                }
                if (!isExist) {
                   vm.photoSelectAll.push(item);
                }
                return
            }
            // 取消选择图片
            for(var i=0; i<vm.photoSelectAll.length;i++){
                var photoSelect = vm.photoSelectAll[i];
                if (photoSelect.id == item.id) {
                    vm.photoSelectAll.splice(i,1);
                    return
                }
            }
        }

        function selectAllBtnState(){
            // 多选按钮状态
            vm.phoneAllSelected = true;
            for(var i=0; i<vm.photoArray.length; i++){
                vm.photoArray[i].selected = true;
                for(var j=0; j<vm.photoArray[i].galleries.length; j++){
                    if(!vm.photoArray[i].galleries[j].selected){
                        vm.photoArray[i].selected = false;
                        vm.phoneAllSelected = false;
                    }
                }
            }
        }
        //添加图片
        $scope.photoSave = function () {
            if (vm.pictureType == 'spread' && vm.photoSelectAll.length % 2 === 1){
                threeInfo("请选择偶数数量的照片");
                return
            }
            vm.page1 = true;
            vm.page2 = false;
            if(vm.photoSelectAll.length>0){
                for(var i=0; i<vm.photoSelectAll.length; i++){
                    vm.photoSelectAll[i].seq = i+1;
                }
                abook(true);
            }
        }
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[1];
            return arrDPI;
        }
        function fn($event) {
            var $event = $event || window.event;
            if($event && $event.preventDefault){
                $event.preventDefault();
            }
            else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }


        //主页
        vm.finalArray = [];
        vm.totalPrice = 0;
        vm.currentNavtab = 0;
        vm.pictureType = 'single';
        function init(){
            Product.get({id: vm.pid, cancelled: false,fixXMLLine:true}, function (res) {
                var product = res;
                vm.pictureType = res.pictureType || 'single';
                vm.typeImageData=res;
                var defaultItem = null;
                for (var i = 0; i < product.itemValues.length; i++) {
                    var tempItem= product.itemValues[i];
                    if(!tempItem.cancelled && tempItem.productXmlLines != null && tempItem.productXmlLines.length>0){
                        if (tempItem.default) {
                            defaultItem = tempItem;
                            vm.currentNavtab = vm.finalArray.length;
                        }
                        var name='';
                        for (var j = 0; j < tempItem.objectValues.length; j++) {
                            var object=tempItem.objectValues[j];
                            name+=object.propertyValueValue;
                        }
                        vm.finalArray.push({name:name,item:tempItem});
                    }
                }
                if(vm.finalArray.length > 0){
                    if (!defaultItem) {
                        defaultItem = vm.finalArray[0].item;
                        vm.currentNavtab = 0;
                    }
                    for (var i = 0; i< defaultItem.productXmlLines.length; i++) {
                        var xmlline = defaultItem.productXmlLines[i];
                        if (xmlline.cancelled) {
                            continue;
                        }
                        getXmlToDucoment(xmlline, defaultItem);
                        break;
                    }
                }else {
                    swal({
                        title: "提示",
                        text: "获取模板失败",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 3000,
                        showConfirmButton: false
                    });
                    vm.placeOrder = false;
                }
            })
        }
        function init1(){
            Product.get({id: vm.pid, cancelled: false,fixXMLLine:true}, function (res) {
                var product = res;
                vm.pictureType = res.pictureType || 'single';
                vm.typeImageData=res;
                var defaultItem = null;
                for (var i = 0; i < product.itemValues.length; i++) {
                    var tempItem= product.itemValues[i];
                    if(tempItem.id == vm.productLine.itemValueId){
                        defaultItem = tempItem;
                        vm.currentNavtab= vm.finalArray.length;
                        var name='';
                        for (var j = 0; j < tempItem.objectValues.length; j++) {
                            var object=tempItem.objectValues[j];
                            name+=object.propertyValueValue;
                        }
                        vm.finalArray.push({name:name,item:tempItem});
                    }
                }
                if(vm.finalArray.length > 0){
                    if (!defaultItem) {
                        defaultItem = vm.finalArray[0].item;
                        vm.currentNavtab = 0;
                    }
                    for (var i = 0; i< defaultItem.productXmlLines.length; i++) {
                        var xmlline = defaultItem.productXmlLines[i];
                        if (xmlline.cancelled) {
                            continue;
                        }
                        getXmlToDucoment(xmlline, defaultItem);
                        break;
                    }
                }else {
                    swal({
                        title: "提示",
                        text: "获取模板失败",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 3000,
                        showConfirmButton: false
                    });
                    vm.placeOrder = false;
                }
            })
        }
        function init2(){
            vm.isEdit = true;
            Article.get({id: $stateParams.aid}, function (res) {
                vm.article = res;
                getPhotoSelectAll(res.document.galleries);
                ItemValue.get({id: res.itemValueId}, function (resp) {
                    vm.pictureType = resp.pictureType || 'single';
                    $scope.template = angular.copy(res.document);
                    documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                        ratioInfo(page)
                    }, function () {
                        $timeout(function () {
                            vm.article.skuNumber = resp.skuNumber;
                            vm.article.productBokeh = resp.productBokeh;
                            vm.article.productBokehType = resp.productBokehType || 'None';
                            vm.article.bokehtype = 'None';
                            vm.placeOrder = true;
                            vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                            vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                            vm.signalPage1.backup = false;
                            vm.signalPage2.backup = false;
                            var documentPage = $scope.template.pages.page;
                            vm.data = [];
                            if (vm.pictureType == 'single') {
                                for (var i = 0; i < documentPage.length; i++) {
                                    if(!documentPage[i].backup){
                                        var isRepetition = false;
                                        for (var j = 0; j < vm.data.length; j++) {
                                            if (documentPage[i].uuid === vm.data[j].uuid) {
                                                isRepetition = true;
                                                vm.data[j].quantity++
                                                break;
                                            }
                                        }
                                        if (!isRepetition) {
                                            documentPage[i].quantity = 1;
                                            vm.data.push(documentPage[i])
                                        }
                                    }
                                }
                                ;
                            }
                            if (vm.pictureType == 'spread') {
                                var _data = [];
                                for (var i = 0; i < documentPage.length; i++) {
                                    var _documentPage2 = documentPage[i];
                                    if(!_documentPage2.backup){
                                        var isRepetition1 = false;
                                        if (i % 2 === 0) {
                                            //偶数
                                            for (var j = 0; j < vm.data.length; j++) {
                                                if (_documentPage2.uuid === vm.data[j][0].uuid) {
                                                    vm.data[j][0].quantity++;
                                                    isRepetition1 = true;
                                                    break;
                                                }
                                            }
                                            if (!isRepetition1) {
                                                _documentPage2.quantity = 1;
                                                _data.push(_documentPage2);
                                            }
                                        } else if (i % 2 === 1) {
                                            //奇数
                                            if (_data.length > 0) {
                                                _data.push(_documentPage2);
                                                vm.data.push(_data);
                                                _data = [];
                                            }
                                        }
                                    }
                                }
                                ;
                            }
                            console.log(vm.data)
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            getCostMethod();
                        }, 1000)
                    })
                });
                Product.get({id: res.productId, cancelled: false,fixXMLLine:true}, function (resp) {
                    var product = resp;
                    vm.typeImageData=resp;
                    var defaultItem = null;
                    for (var i = 0; i < product.itemValues.length; i++) {
                        var tempItem= product.itemValues[i];
                        if(tempItem.id == res.itemValueId){
                            defaultItem = tempItem;
                            vm.currentNavtab= vm.finalArray.length;
                            vm.productActiveSku = defaultItem;
                            var name='';
                            for (var j = 0; j < tempItem.objectValues.length; j++) {
                                var object=tempItem.objectValues[j];
                                name+=object.propertyValueValue;
                            }
                            vm.finalArray.push({name:name,item:tempItem});
                        }
                    }
                })
            })
        }

        function getPhotoSelectAll(galleries){
            vm.photoSelectAll = [];
            if(galleries && galleries.gallery && galleries.gallery.length>0){
                galleries.gallery.forEach(function (gallery){
                    // if(gallery.identifier.indexOf('http')>-1){
                    //     gallery.identifier = gallery.identifier.substring(gallery.identifier.lastIndexOf('/')+1);
                    // }
                    vm.photoSelectAll.push(gallery)
                })
            }
        }

        vm.isEditorPhotoUploadPopup = false;
        $scope.showGallery = function (editTip) {
            if(vm.typeImageData.editTip && editTip){
                vm.isEditorPhotoUploadPopup = true;
            }else{
                $scope.editorPhotoUploadPopupChange();
            }
        }
        $scope.editorPhotoUploadPopupChange = function (){
            vm.isEditorPhotoUploadPopup = false;
            vm.page1 = false;
            vm.page2 = true;
            vm.currentNav = 'gallery';//folder
            vm.photoAll=[];
            vm.indexNum = 0;
            vm.photoCompleted = false;
            vm.maxId = null;
            // vm.photoSelectAll = [];
            loadAll('isNull');
        }
        $scope.getBack = function (item) {
            if(item == 'page2'){
                vm.page1 = true;
                vm.page2 = false;
            }
            if(item == 'page3'){
                vm.page1 = true;
                vm.page3 = false;
                scroll('page'+vm.singleIndex)
            }
            if(item == 'page9'){
                vm.page2 = true;
                vm.page9 = false;
                loadFolders();
            }
            $scope.photoGalleryHide();
        };
        $scope.templateShow = function () {
            vm.istemplate = true;
            productInit();
            // ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.templateHide = function () {
            vm.istemplate = false;
        }
        function productInit() {
            vm.tempList = [];
            vm.productActiveSku = vm.finalArray[vm.currentNavtab].item;
            vm.finalArray[vm.currentNavtab].item.productXmlLines.forEach(function (productXmlLine) {
                vm.tempList.push(productXmlLine)
            })
        }
        $scope.productXmlLineClick = function (index , line,isItemValue) {
            if(line.productXmlId == vm.article.productXmlId){
                return
            }
            if(isItemValue){
                vm.threeLoading = true;
                vm.threeLoadingText = '替换模板中…';
                updateProductXml(index , line)
            }else{
                vm.threeIsModal = true;
                $scope.threeModalSave = function (){
                    vm.threeIsModal = false;
                    vm.threeLoading = true;
                    vm.threeLoadingText = '替换模板中…';
                    updateProductXml(index , line)
                }
            }
        }
        function updateProductXml(index,line){
            var singleData = angular.copy(vm.data);
            getXmlToDucoment1(line,function () {
                vm.counts = 0;
                angular.forEach($scope.template.pages.page, function (page, i) {
                    if(!page.backup){
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.geometry) {
                                vm.counts++;
                            }
                        })
                    }
                });
                $scope.photos = [];
                vm.data = [];
                var _photo = [];
                if (vm.pictureType == 'single') {
                    singleData.forEach(function (data) {
                        var _photo1 = [];
                        data.levels.level.forEach(function (level) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                var photo = {
                                    identifier:level.imagebox.image.resource.identifier,
                                    width:level.imagebox.image.imgWidth,
                                    height:level.imagebox.image.imgHeight,
                                    imgSize:level.imagebox.image.size,
                                    imageInfo:level.imagebox.image.imageInfo,
                                    quantity:data.quantity,
                                    uuid:data.uuid,
                                    resourceid:level.imagebox.image.resourceid,
                                    id:level.imagebox.image.resourceid
                                }
                                _photo1.push(photo)
                            }
                        })
                        _photo.push(_photo1)
                    })
                }
                if (vm.pictureType == 'spread') {
                    singleData.forEach(function (singleData1) {
                        singleData1.forEach(function (data) {
                            var _photo1 = [];
                            data.levels.level.forEach(function (level) {
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                    var photo = {
                                        identifier:level.imagebox.image.resource.identifier,
                                        width:level.imagebox.image.imgWidth,
                                        height:level.imagebox.image.imgHeight,
                                        imgSize:level.imagebox.image.size,
                                        imageInfo:level.imagebox.image.imageInfo,
                                        quantity:data.quantity,
                                        uuid:data.uuid,
                                        resourceid:level.imagebox.image.resourceid,
                                        id:level.imagebox.image.resourceid
                                    }
                                    _photo1.push(photo)
                                }
                            })
                            _photo.push(_photo1)
                        })
                    })
                }
                abook1(_photo,function () {
                    if (vm.pictureType == 'spread') {
                        vm.data.forEach(function (item, index) {
                            item[0].quantity = singleData[index][0].quantity;
                            item[1].quantity = singleData[index][1].quantity;
                        })
                    }
                    if (vm.pictureType == 'single') {
                        vm.data.forEach(function (item, index) {
                            item.quantity = singleData[index].quantity;
                        })
                    }
                    $timeout(function () {
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        vm.page1 = true;
                        vm.page3 = false;
                        vm.levelIndex = null;
                        getCostMethod();
                        console.log(vm.data);
                    }, 1000)
                })
            });
        }
        vm.productActiveSku = {};
        //获取模版信息
        function getXmlToDucoment(xmlline, defaultItem){
            vm.productActiveSku = defaultItem;
            vm.article.cover = xmlline.coverImg;
            vm.article.themeClassifyId = xmlline.productXmlThemeId;
            vm.article.themeClassifyName = xmlline.productXmlThemeName;
            vm.article.productXmlId = xmlline.productXmlId;
            vm.article.productXmlName = xmlline.productXmlName;
            vm.article.musicIdentify = xmlline ? xmlline.musicIdentify : defaultItem.musicIdentify;
            vm.article.sku = defaultItem.sku;
            vm.article.skuNumber = defaultItem.skuNumber;
            vm.article.name =defaultItem.productName;
            vm.article.productId = defaultItem.productId;
            vm.article.productName = defaultItem.productName;
            vm.article.categoryId = defaultItem.categoryId;
            vm.article.categoryName = defaultItem.categoryName;
            vm.article.categoryStoreId = defaultItem.categoryStoreId;
            vm.article.categoryStoreName = defaultItem.categoryStoreName;
            vm.article.editorType = defaultItem.productEditorType;
            vm.article.productBokeh = defaultItem.productBokeh;
            vm.article.productBokehType = defaultItem.productBokehType || 'None';
            vm.article.itemValueId = defaultItem.id;
            if (!defaultItem.productBokeh) {
                vm.article.bokehtype = 'None';
            } else {
                vm.article.bokehtype = defaultItem.productBokehType || 'None';
            }
            Convert.xmlToDocument2({
                identifier: xmlline.productXmlIdentifier,
                provider: "qiniu",
                editorType:xmlline.productXmlVersion == "4.0" ? "" : vm.article.editorType,
                patternType:vm.typeImageData.patternType,
            }, function (res) {
                $scope.template = angular.copy(res);
                documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                    ratioInfo(page)
                }, function () {
                    $timeout(function () {
                        vm.placeOrder = true;
                        vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                        vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                        vm.signalPage1.backup = false;
                        vm.signalPage2.backup = false;
                        abook();
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                    }, 1000)
                })
            });
        }
        function getXmlToDucoment1(xml,callback) {
            vm.article.cover = xml.coverImg;
            vm.article.themeClassifyId = xml.productXmlThemeId;
            vm.article.themeClassifyName = xml.productXmlThemeName;
            vm.article.productXmlId = xml.productXmlId;
            vm.article.productXmlName = xml.productXmlName;
            vm.article.musicIdentify = xml.musicIdentify;
            vm.article.itemValueId = xml.itemValueId;
            Convert.xmlToDocument2({
                identifier: xml.productXmlIdentifier,
                provider: "qiniu",
                editorType:vm.article.editorType,
                pictureType:vm.pictureType,
            }, function (res) {
                vm.counts = 0;
                angular.forEach(res.pages.page, function (page, i) {
                    if(!page.backup){
                        angular.forEach(page.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.geometry) {
                                vm.counts++;
                            }
                        })
                    }
                });
                if (!res.status) {
                    if (!res.pages.page || res.pages.page.length == 0) {
                        swal({
                            title: $translate.instant("font.warmPrompt"),
                            text: $translate.instant("editorCommon.getTemplateError"),
                            imageUrl: Theme.sweetHintImg(),
                            timer: 3000,
                            showConfirmButton: false
                        });
                        vm.placeOrder = false;
                        return
                    }
                    $scope.template = angular.copy(res);
                    documentAnalysis($scope, $http, vm, $scope.template, FontManagement, 'processing', function (page) {
                        ratioInfo(page)
                    }, function () {
                        $timeout(function () {
                            vm.loading = false;
                            vm.placeOrder = true;
                            vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                            vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                            vm.signalPage1.backup = false;
                            vm.signalPage2.backup = false;
                            if(callback){
                                callback();
                                return
                            }
                            abook();
                        }, 1000)
                    })
                } else {
                    swal({
                        title: $translate.instant("font.warmPrompt"),
                        text: res.message,
                        imageUrl: Theme.sweetHintImg(),
                        timer: 3000,
                        showConfirmButton: false
                    });
                }
            });
        }
        // function ratioInfo(item) {
        //     var windowRatio = 0.4;
        //     var windowRatio1 = 0.9;
        //     item.custom.ratio = $scope.dWidth * windowRatio / item.custom.width;
        //     item.custom.ratio1 = $scope.dWidth * windowRatio1 / item.custom.width;
        //     item.custom.thumbnailScale = 300 / item.custom.width;
        // }
        function ratioInfo(item) {
            var windowRatio = 0.4;
            var windowRatio1 = 0.9;
            item.custom.ratio = $scope.dWidth * windowRatio / item.custom.width;
            // item.custom.ratio1 = $scope.dWidth * windowRatio1 / item.custom.width;


            vm.windowWidth = $(window).width() * 0.9;
            vm.windowHeight = $(window).height() - 320;
            var ratio = item.custom.width / item.custom.height;
            var ratio1 = vm.windowWidth / vm.windowHeight;
            if (ratio >= ratio1) {
                item.custom.ratio1 = vm.windowWidth / item.custom.width;
            } else {
                item.custom.ratio1 = vm.windowHeight / item.custom.height;
            }

            item.custom.thumbnailScale = 300 / item.custom.width;
        }
        $scope.finalArrayTab = function (index) {
            if (index!=vm.currentNavtab) {
                // vm.data = [];
                vm.currentNavtab = index;
                $scope.templateHide();
                productInit();
                $scope.productXmlLineClick(0,vm.tempList[0],true)
            }
        }

        vm.data = [];
        function abook(isSave) {
            vm.threeLoading = true;
            vm.threeLoadingText = '加载中...';
            var _data = [];
            var _isSignalPage = true;
            for (var i = 0; i < vm.photoSelectAll.length; i++) {
                var index = i + 1;
                if(vm.pictureType == 'single'){
                    var _page = angular.copy(vm.signalPage1);
                }else{
                    if(_isSignalPage){
                        var _page = angular.copy(vm.signalPage1);
                    }else{
                        var _page = angular.copy(vm.signalPage2);
                    }
                }
                var _isImagebox = false;
                _page.levels.level.forEach(function (level) {
                    if (level.imagebox) {
                        _isImagebox = true;
                        level.imagebox.bokehtype = 'None';
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.image = {
                            angle: 0,
                            filter: null,
                            filterResource: null,
                            offsetX: 0,
                            offsetY: 0,
                            resource: {identifier: vm.photoSelectAll[i].identifier, provider: "qiniu"},
                            size: vm.photoSelectAll[i].imgSize,
                            provider: "qiniu",
                            resourceid: vm.photoSelectAll[i].id,
                            scale: 1,
                            width: vm.photoSelectAll[i].width,
                            height: vm.photoSelectAll[i].height,
                            imageInfo: vm.photoSelectAll[i].imageInfo,
                        };
                        level.imagebox.blurredrectangle = {
                            width: '',
                            height: '',
                            x: '',
                            y: '',
                        };
                        if(vm.pictureType == 'single'){
                            getBlurredrectangle(level.imagebox, 0);
                        }else{
                            getBlurredrectangle(level.imagebox, i);
                        }
                    }
                })
                if (vm.pictureType == 'single') {
                    _page.quantity = 1;
                    _page.uuid = uuid();
                    vm.data.push(_page)
                }
                if (vm.pictureType == 'spread') {
                    _isSignalPage = !_isSignalPage;
                    if(!_isImagebox){
                        i--
                    }
                    _page.quantity = 1;
                    _page.uuid = uuid();
                    if (index % 2 === 0) {
                        //偶数
                        _page.uuid = _data[0].uuid;
                        _data.push(_page);
                        vm.data.push(_data)

                    } else if (index % 2 === 1) {
                        //奇数
                        _page.uuid = uuid();
                        _data = [];
                        _data.push(_page);
                        if(i == vm.photoSelectAll.length-1){
                            var _page1 = angular.copy(vm.signalPage2);
                            _page1.levels.level.forEach(function (level) {
                                if(level.imagebox){
                                    _isImagebox = true;
                                    level.imagebox.bokehtype = 'None';
                                    level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                    level.imagebox.image = {
                                        angle: 0,
                                        filter: null,
                                        filterResource: null,
                                        offsetx: 0,
                                        offsety: 0,
                                        resource: {identifier: "", provider: "qiniu"},
                                        size: "",
                                        provider: "qiniu",
                                        resourceid: "",
                                        scale: 1,
                                        width:"",
                                        height:"",
                                        imageInfo:{},
                                    };
                                    level.imagebox.blurredrectangle = {
                                        width:'',
                                        height:'',
                                        x:'',
                                        y:'',
                                    };
                                }
                            })
                            _page1.uuid =_data[0].uuid;
                            _data.push(_page1);
                            vm.data.push(_data)
                        }
                    }
                }
            }
            vm.photoSelectAll = [];
            if (vm.data.length > 0) {
                getCostMethod();
                if(isSave){
                    $timeout(function (){
                        vm.threeLoading = false;
                        $scope.saveTab0(true)
                    },1000)
                }else{
                    vm.threeLoading = false;
                }
            }else{
                vm.threeLoading = false;
            }
        }
        function abook1(gallaryPic,callback) {
            var _data = [];
            var _isSignalPage = true;
            for(var i=0; i<gallaryPic.length; i++){
                var index = i+1;
                if(vm.pictureType == 'single'){
                    var _page = angular.copy(vm.signalPage1);
                }else{
                    if(_isSignalPage){
                        var _page = angular.copy(vm.signalPage1);
                    }else{
                        var _page = angular.copy(vm.signalPage2);
                    }
                }
                var _isImagebox = false;
                _page.levels.level.forEach(function (level,i1) {
                    if(level.imagebox){
                        var _gallaryPic = null;
                        if(gallaryPic[i]){
                            _gallaryPic = gallaryPic[i][i1] ? gallaryPic[i][i1] : gallaryPic[i][0]
                        }
                        _isImagebox = true;
                        level.imagebox.bokehtype = 'None';
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.image = {
                            angle: 0,
                            filter: null,
                            filterResource: null,
                            offsetx: 0,
                            offsety: 0,
                            resource: {identifier: !_gallaryPic ? "" : _gallaryPic.identifier, provider: "qiniu"},
                            size: !_gallaryPic ? "" : _gallaryPic.imgSize,
                            provider: "qiniu",
                            resourceid: !_gallaryPic ? "" : _gallaryPic.id,
                            scale: 1,
                            width:!_gallaryPic ? "" : _gallaryPic.width,
                            height:!_gallaryPic ? "" : _gallaryPic.height,
                            imageInfo:!_gallaryPic ? {} : _gallaryPic.imageInfo,
                        };
                        level.imagebox.blurredrectangle = {
                            width:'',
                            height:'',
                            x:'',
                            y:'',
                        };
                        if(vm.pictureType == 'single'){
                            getBlurredrectangle(level.imagebox, 0);
                        }else{
                            getBlurredrectangle(level.imagebox, i);
                        }
                    }
                })

                if(vm.pictureType == 'single'){
                    _page.quantity=1;
                    _page.uuid = uuid();
                    vm.data.push(_page)
                }
                if(vm.pictureType == 'spread'){
                    _isSignalPage = !_isSignalPage;
                    if(!_isImagebox){
                        i--
                    }
                    _page.quantity=1;
                    _page.uuid = uuid();
                    if (index % 2 === 0){
                        //偶数
                        _page.uuid =_data[0].uuid;
                        _data.push(_page);
                        vm.data.push(_data)

                    } else if (index % 2 === 1){
                        //奇数
                        _page.uuid = uuid();
                        _data = [];
                        _data.push(_page);
                        if(i == gallaryPic.length-1){
                            var _page1 = angular.copy(vm.signalPage2);
                            _page1.levels.level.forEach(function (level, i1) {
                                if(level.imagebox){
                                    _isImagebox = true;
                                    level.imagebox.bokehtype = 'None';
                                    level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                    level.imagebox.image = {
                                        angle: 0,
                                        filter: null,
                                        filterResource: null,
                                        offsetx: 0,
                                        offsety: 0,
                                        resource: {identifier: "", provider: "qiniu"},
                                        size: "",
                                        provider: "qiniu",
                                        resourceid: "",
                                        scale: 1,
                                        width:"",
                                        height:"",
                                        imageInfo:{},
                                    };
                                    level.imagebox.blurredrectangle = {
                                        width:'',
                                        height:'',
                                        x:'',
                                        y:'',
                                    };
                                }
                            })
                            _page1.uuid =_data[0].uuid;
                            _data.push(_page1);
                            vm.data.push(_data)
                        }
                    }
                }
            }
            if (vm.data.length > 0) {
                getCostMethod();
            }
            if(callback){callback()}
        }

        function uuid() {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";

            var uuid = s.join("");
            return uuid;
        };
        function getBlurredrectangle(imagebox, index) {
            var item = imagebox;
            if (!item.image) {
                return
            }
            addImagebox($http, imagebox, function () {
                var newImg = angular.copy({
                    width: imagebox.image.width,
                    height: imagebox.image.height
                })
                if ((imagebox.geometry.width - imagebox.geometry.height) * (newImg.width - newImg.height) < 0) {
                    if (index % 2 === 0) {
                        if (imagebox.flip) {
                            imagebox.image.angle = imagebox.image.angle + 90;
                        } else {
                            imagebox.image.angle = imagebox.image.angle - 90;
                        }
                        imagebox.image.width = newImg.height;
                        imagebox.image.height = newImg.width;
                    }
                    if (index % 2 === 1) {
                        if (imagebox.flip) {
                            imagebox.image.angle = imagebox.image.angle - 90;
                        } else {
                            imagebox.image.angle = imagebox.image.angle + 90;
                        }
                        imagebox.image.width = newImg.height;
                        imagebox.image.height = newImg.width;
                    }
                } else {
                    imagebox.image.angle = imagebox.image.angle;
                }
                getBackgroundSource3(imagebox, imagebox.geometry.width, imagebox.geometry.height, imagebox.image, imagebox.bokehtype, $scope);
            });
            photoSelectAllInit();
        }

        //mm转px
        function transitionPx(item) {
            return item * vm.windowDpi / 25.4;
        }
        //px转mm
        function transitionMm(item) {
            return item * 25.4 / vm.windowDpi;
        }

        $scope.single = function (index, pageIndex) {
            vm.singleData = [];
            vm.levelIndex = 0;
            if (vm.pictureType == 'single') {
                vm.singleData = angular.copy(vm.data);
                vm.singleIndex = index;
            }
            if (vm.pictureType == 'spread') {
                vm.data.forEach(function (data) {
                    data.forEach(function (page) {
                        vm.singleData.push(angular.copy(page))
                    })
                })
                vm.singleIndex = index * 2 + pageIndex;
            }
            vm.page1 = false;
            vm.istemplate = false;
            vm.page3 = true;
        }
        $scope.singleIndexClick = function (item) {
            vm.levelIndex = 0;
            if ($scope.zfInit() != item) {
                if (item) {
                    vm.singleIndex++
                } else {
                    vm.singleIndex--
                }
            }
            $scope.photoGalleryHide();
        }
        $scope.previousPage = function () {
            if (vm.pictureType == 'spread') {
                if (!$scope.zfInit()) {
                    vm.singleIndex -= 2;
                } else {
                    vm.singleIndex--
                }
            } else {
                vm.singleIndex--;
            }
            if (vm.singleIndex < 0) {
                vm.singleIndex = 0;
            }
            vm.levelIndex = 0;
            $scope.photoGalleryHide();
        }
        $scope.nextPage = function () {
            if (vm.pictureType == 'spread') {
                if (!$scope.zfInit()) {
                    vm.singleIndex += 2;
                } else {
                    vm.singleIndex++;
                }
            } else {
                vm.singleIndex++;
            }

            if (vm.singleIndex > vm.singleData.length - 1) {
                vm.singleIndex = vm.singleData.length - 1;
            }
            vm.levelIndex = 0;
            $scope.photoGalleryHide();
        }
        $scope.zfInit = function () {
            if(vm.pictureType == 'spread'){
                if (vm.singleIndex % 2 === 0) {
                    return false
                } else if (vm.singleIndex % 2 === 1) {
                    return true
                }
            }
        }
        $scope.singleInit = function (index) {
            if (vm.pictureType == 'spread') {
                return Math.ceil(index / 2)
            }
            return index
        }
        $scope.subtractDeleteTab = function ($event, index) {
            vm.data.splice(index, 1);
            getCostMethod()
            fn($event)
        }
        $scope.subtractQuantityTab = function (data) {
            if(data.quantity>1){
                data.quantity--;
                getCostMethod()
            }
        }
        $scope.pushQuantityTab = function (data) {
            data.quantity++;
            if (vm.productActiveSku.jhiPackage && vm.store.orderWay == 'orderThenEditor') {
                var quantity=0;
                vm.data.forEach(function (item) {
                    quantity += item.quantity
                })
                if (quantity > vm.productActiveSku.packageQuantity) {
                    data.quantity--;
                    threeInfo("数量已满"+vm.productActiveSku.packageQuantity+"张，无法继续添加");
                }
            }
            getCostMethod()
        }
        $scope.quantityChange = function (data) {
            // 正则表达式，匹配正整数
            var regex = /^[1-9]\d*$/;

            // 如果输入值不符合正则表达式，则清空输入
            if (!regex.test(data.quantity)) {
                data.quantity = 1;
            }
            getCostMethod()
        }

        //获取价格
        function getCostMethod(){
            vm.totalCount = 0;
            for (var k = 0; k < vm.data.length; k++) {
                if (vm.pictureType == 'spread') {
                    vm.totalCount += vm.data[k][0].quantity;
                } else {
                    vm.totalCount += vm.data[k].quantity;
                }
            }
        }

        $scope.fillImagebox = function () {
            var item = vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox;

            item.bokehtype = 'Transparent';
            getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope);
        }
        $scope.cutImagebox = function () {
            var item = vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox;
            item.bokehtype = 'None';
            getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope);
        }

        //图片框的操作
        $scope.imageboxSite = function (item) {
            if (!vm.singleData[vm.singleIndex].levels.level[vm.levelIndex] || vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox.bokehtype != 'None') {
                return
            }
            var _imagebox = vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox;
            if(item == 'center'){
                _imagebox.image.offsetx = -(_imagebox.image.width*_imagebox.image.scale - _imagebox.geometry.width)/2/_imagebox.image.scale;
                _imagebox.image.offsety = -(_imagebox.image.height*_imagebox.image.scale - _imagebox.geometry.height)/2/_imagebox.image.scale;
            }
            if(_imagebox.image.offsetx > 0){
                _imagebox.image.offsetx = 0;
            }
            if(_imagebox.image.offsety > 0){
                _imagebox.image.offsety = 0;
            }
        }
        $scope.rotateImagebox = function () {
            imageboxRotate($http,$scope, vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox,!$scope.zfInit());
        }

        /*******************************************照片框处理*************************************************************************/
        var isImagebox = false;
        var stickerDragBox = {};
        window.moveImageboxStart = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            var textIndex = targ.dataset.index;
            textIndex = parseInt(textIndex);
            vm.levelIndex = textIndex;
            var sticker = vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox;
            $scope.$apply();
            if(vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox.bokehtype != 'None'){
                return
            }

            stickerDragBox.x = e.changedTouches[0].clientX != null? e.changedTouches[0].clientX : 0;          //鼠标到页面左边的距离
            stickerDragBox.y = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;          //鼠标到页面顶部的距离
            stickerDragBox.l = sticker.image.offsetx * vm.ratio1;
            stickerDragBox.t = sticker.image.offsety * vm.ratio1;
            stickerDragBox.r = -(sticker.image.width - vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox.geometry.width) * vm.ratio1;
            stickerDragBox.b = -(sticker.image.height - vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox.geometry.height) * vm.ratio1;
            isImagebox = true;
            $scope.$apply();
        }
        window.moveImageboxOn = function (e) {
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            var textIndex = targ.dataset.index;
            textIndex = parseInt(textIndex);
            var sticker = vm.singleData[vm.singleIndex].levels.level[0].imagebox;
            if(vm.singleData[vm.singleIndex].levels.level[textIndex].imagebox.bokehtype != 'None'){
                return
            }
            fn(e);
            var angle = Number(sticker.rotation.angle);
            var cx = (stickerDragBox.l + stickerDragBox.r) / 2;
            var cy = (stickerDragBox.t + stickerDragBox.b) / 2;
            var pt0 = rotatePoint({x: stickerDragBox.x, y: stickerDragBox.y}, {x: cx, y: cy}, -angle);
            var pt1 = rotatePoint({x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY}, {x: cx, y: cy}, -angle);
            var deltaX = pt1.x - pt0.x;
            var deltaY = pt1.y - pt0.y;
            var nl = stickerDragBox.l + deltaX;
            var nt = stickerDragBox.t + deltaY;
            if (nl < stickerDragBox.r) {
                nl = stickerDragBox.r
            };
            if (nl > 0) {
                nl = 0
            };
            if (nt < stickerDragBox.b) {
                nt = stickerDragBox.b
            };
            if (nt > 0) {
                nt = 0
            };

            sticker.image.offsetx = nl / vm.ratio1;
            sticker.image.offsety = nt / vm.ratio1;
            $scope.$apply();
        }
        window.moveImageboxEnd = function (e) {
            fn(e);
            isImagebox = false;
            stickerDragBox = {}
        }
        $scope.singleSave = function () {
            vm.levelIndex = null;
            vm.data = [];
            if (vm.pictureType == 'single') {
                vm.data = vm.singleData;
            }
            if (vm.pictureType == 'spread') {
                var _data = [];
                vm.singleData.forEach(function (data, index) {
                    if (index % 2 === 0) {
                        //偶数
                        _data.push(data);
                    } else if (index % 2 === 1) {
                        //奇数
                        _data.push(data);
                        vm.data.push(_data);
                        _data = [];
                    }
                })
            }
            vm.page1 = true;
            vm.page3 = false;
            $scope.photoGalleryHide();
            scroll('page'+vm.singleIndex)
        };
        function scroll(id){
            $timeout(function () {
                var _top = $("#"+id).offset().top-60;
                $("html, body").animate({
                    scrollTop: _top + "px"
                }, {
                    duration: 0,
                    easing: "swing"
                });
            })
            return false;
        }
            //     var target = $(this.hash);gth){limate({
            //         scrollTop: target.offset().top
            //     }, 1000); false;
            //
            // var element = document.getElementById(id);
            // if(element){
            //     var topOffset = element.getBoundingClientRect().top + window.pageYOffset;
            //     window.scrollTo({
            //         top: topOffset,
            //         behavior: 'smooth'
            //     });
            // }
        // }

        vm.isPageWarnExamine = false;

       $scope.saveTab = function() {
            vm.deleteList = 0;
            var gallieryIds = [];
            for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                var page = $scope.template.pages.page[i];
                if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                    continue;
                }
                for (var j = page.levels.level.length - 1; j >= 0; j--) {
                    var level = page.levels.level[j];
                    if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                        continue;
                    }
                    gallieryIds.push(level.imagebox.image.resourceid);
                }
            }
            if (gallieryIds.length>0) {
                Gallery.checkDeleted(gallieryIds, function(list){
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    if (list == null|| list.length ==0) {
                        $scope.saveTab0()
                    }else{
                        vm.deleteList = list;
                        vm.isDestroyed = true;
                        return;
                    }
                }, function(){
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    MessageService.error("检测图片失败，请稍后再试。");
                })
            }else{
                $scope.saveTab0()
            }
        }

        $scope.removeGallery = function(){
            if (vm.deleteList == null || vm.deleteList.length == 0) {
                return;
            }
            for (var z = vm.deleteList.length - 1; z >= 0; z--) {
                var g = vm.deleteList[z];
                for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                    var page = $scope.template.pages.page[i];
                    if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                        continue;
                    }
                    for (var j = page.levels.level.length - 1; j >= 0; j--) {
                        var level = page.levels.level[j];
                        if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                            continue;
                        }
                        if (level.imagebox.image.resourceid == g.id) {
                            level.imagebox.image.resource.identifier = "";
                            level.imagebox.image.resource.adaptation = "";
                            level.imagebox.image.resource.provider = "";
                            level.imagebox.image.resource.offsetx = 0;
                            level.imagebox.image.resource.offsety = 0;
                            level.imagebox.image.resource.height = "";
                            level.imagebox.image.resource.width = "";
                            level.imagebox.image.resource.scale = 1;
                            level.imagebox.image.resource.imgSize = "";
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.imageInfo = null;
                            break;
                        }
                    }
                }
            }
        }

        $scope.galleryDestroyConfirm = function(){
            $scope.removeGallery();
            $scope.saveTab0();
        };



        function articleOverdue(callback){
            if(vm.article.id) {
                Article.get({id: vm.article.id}, function (article) {
                    if(article.fileName != vm.article.fileName){
                        MessageService.minConfirm({
                            title:"提示",
                            cancel:"取消",
                            confirm:"刷新",
                            msg:"保存失败，当前作品不是最新版本，请刷新后再操作。"
                        },function(){
                            location.reload();
                        })
                    }else{
                        callback()
                    }
                })
                return
            }
            callback()
        }
        $scope.saveTab0 = function (isSave) {
            if(!vm.placeOrder){
                return
            }
            vm.threeLoading = true;
            articleOverdue(function () {
                vm.threeLoading = false;
                var _data = [];
                var _data1 = angular.copy(vm.data);
                if (vm.pictureType == 'single') {
                    angular.forEach(_data1, function (item) {
                        for (var i = 0; i < item.quantity; i++) {
                            var itemData = angular.copy(item);
                            _data.push(itemData)
                        }
                    });
                    _data.forEach(function (data) {
                        data.quantity = 1;
                    })
                }
                if(vm.pictureType == 'spread'){
                    _data1.forEach(function (data) {
                        for(var i=0; i<data[0].quantity; i++){
                            _data.push(data[0])
                            _data.push(data[1])
                        }
                    })
                    _data.forEach(function (data) {
                        data.quantity = 1;
                    })
                }
                if(vm.data.length == 0){
                    threeInfo("请先进行定制哦~");
                    return
                }
                if(vm.store.orderWay == 'orderThenEditor'){
                    getCostMethod();
                    if(vm.totalCount > vm.productLine.quantity){
                        threeInfo("订单可提交冲印数量为"+vm.productLine.quantity+"张，当前数量为"+vm.totalCount+"，请删除多余照片再保存。",2000);
                        return
                    }
                }
                var _imageInfoExamine = imageInfoExamine(_data);
                if(!isSave && _imageInfoExamine.length>0){
                    threeInfo('系统检测到当前作品第'+_imageInfoExamine.join('，')+'页的图片存在异常，请修正再保存');
                    return
                }
                if(!isSave && !vm.isPageWarnExamine && pageWarnExamine(_data, vm.imgSize, vm.imgDpi)){
                    vm.isPageWarnExamine = true;
                    return
                }
                vm.isPageWarnExamine = false
                vm.threeLoading = true;
                vm.threeLoadingText = isSave?'即时保存...':'提交中...';
                vm.isCover = false;
                $timeout(function () {
                    if(!vm.isCover){
                        vm.isCover = true;
                        vm.article.covered=false;
                        vm.article.cover=null;
                        saveTab1(_data,isSave)
                    }
                },5000)
                thumbnailInit(function (res){
                    if(!vm.isCover){
                        vm.isCover = true;
                        saveTab1(_data,isSave)
                    }
                })
            })
        }
        function saveTab1(_data,isSave){
            if(_data && _data.length > 0){
                var _page = [];
                angular.forEach(_data, function (item) {
                    for(var i=0; i<item.quantity; i++){
                        var _item = angular.copy(item);
                        _page.push(_item)
                    }
                })

                $scope.template.pages.page = _page;
                $scope.template.hostinfo = hostInfo();
                $scope.template = documentMM($scope.template, $scope.domain);
                vm.article.jhiPackage = vm.productActiveSku.jhiPackage;
                vm.article.packageQuantity = vm.productActiveSku.packageQuantity;
                vm.article.pictureType = vm.pictureType;
                if (vm.aid) {
                    vm.article.document.pages.page = $scope.template.pages.page;
                    vm.article.document.galleries.gallery = vm.photoSelectAll;
                    vm.article.completed = $scope.saveClass();
                    Article.update(vm.article, function (resp) {
                        if(vm.store.orderWay == 'orderThenEditor'){
                            works(resp, function (){
                                if(!isSave){
                                    $state.go("editorPhotoPreview", {storeId: vm.storeId, aid: resp.id, soid:vm.salesOrderId, plid:vm.productLineId}, {reload: true});
                                }else{
                                    getArticle(resp.id)
                                }
                                $scope.isSave = false;
                                vm.threeLoading = false;
                            })
                        }else{
                            if(!isSave){
                                $state.go("editorPhotoPreview", {storeId: vm.storeId, aid: resp.id, soid:vm.salesOrderId, plid:vm.productLineId}, {reload: true});
                            }else{
                                getArticle(resp.id)
                            }
                            $scope.isSave = false;
                            vm.threeLoading = false;
                        }
                    }, function (resp) {
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        threeInfo("保存失败");
                    });
                }else{
                    var product=vm.finalArray[vm.currentNavtab].item;
                    var _document = $scope.template;
                    _document.galleries = {gallery:vm.photoSelectAll};
                    _document.replace = true;
                    var uploadData={
                        categoryId:product.categoryId,
                        categoryName: product.categoryName,
                        categoryStoreId: product.categoryStoreId,
                        categoryStoreName: product.categoryStoreName,
                        completed:$scope.saveClass(),
                        document:_document,
                        editorType: product.productEditorType,
                        itemValueId: product.id,
                        name: product.productName,
                        productId: product.productId,
                        productName: product.productName,
                        sku: product.sku,
                        skuNumber: product.skuNumber,
                        itemValueLineId: product.itemValueLines[0].id,
                        lineSku: product.itemValueLines[0].sku,
                        lineSkuNumber: product.itemValueLines[0].skuNumber,
                        productType: product.productType,
                        productXmlId: vm.article.productXmlId,
                        productXmlName: vm.article.productXmlName,
                        cover: vm.article.cover,
                        themeClassifyId: vm.article.productXmlThemeId,
                        themeClassifyName: vm.article.productXmlThemeName,
                        musicIdentify: vm.article.musicIdentify,
                        pageCount: vm.article.pageCount,
                        jhiPackage: vm.article.jhiPackage,
                        packageQuantity: vm.article.packageQuantity,
                        pictureType: vm.article.pictureType,
                    }
                    Article.save(uploadData, function (resp) {
                        if(!isSave){
                            swal({
                                title: "保存成功",
                                text: "作品已同步到我的作品",
                                imageUrl: Theme.sweetSuccedImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        }
                        if(vm.store.orderWay == 'orderThenEditor'){
                            works(resp, function (){
                                if(!isSave){
                                    $state.go("editorPhotoPreview", {storeId: vm.storeId, aid: resp.id, soid:vm.salesOrderId, plid:vm.productLineId}, {reload: true});
                                }else{
                                    getArticle(resp.id)
                                }
                                $scope.isSave = false;
                                vm.threeLoading = false;
                            })
                        }else{
                            if(!isSave){
                                $state.go("editorPhotoPreview", {storeId: vm.storeId, aid: resp.id, soid:vm.salesOrderId, plid:vm.productLineId}, {reload: true});
                            }else{
                                getArticle(resp.id)
                            }
                            $scope.isSave = false;
                            vm.threeLoading = false;
                        }
                    }, function (resp) {
                        $scope.isSave = false;
                        swal({
                            title: "保存失败",
                            text: "保存出错了，请稍后再试，或者联系工作人员",
                            imageUrl: Theme.sweetHintImg(),
                            timer: 3000,
                            showConfirmButton: false
                        });

                    });
                }
            }
        };
        function getArticle(id){
            vm.aid = id;
            Article.get({id: id}, function (res) {
                vm.article = res;
                vm.isEdit = true;
                $scope.isSave = false;
                vm.threeLoading = false;
                vm.threeLoadingText = '';
            })
        }
        function works(article, callback){
            if (vm.aid) {
                SalesOrder.updateByArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id
                }, function (res) {
                    callback()
                }, function (error) {
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    swal({
                        title: "更新订单失败",
                        text: error.data.message,
                        imageUrl: Theme.sweetHintImg(),
                        timer: 3000,
                        showConfirmButton: false
                    });
                })
            }else{
                SalesOrder.updateArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id,
                    copy: 1,
                }, function (res) {
                    callback()
                }, function (error) {
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    swal({
                        title: "更新订单失败",
                        text: error.data.message,
                        imageUrl: Theme.sweetHintImg(),
                        timer: 3000,
                        showConfirmButton: false
                    });
                })
            }
        }
        function threeInfo(item,time){
            vm.threeError = true;
            vm.threeErrorText=item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText="";
            },(time || 1500))
        }
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreConfig.getAllByTypes({storeId: vm.storeId,type: ["IMGSIZE", "IMGDPI"]}, function (res) {
            if(res.status == 200){
                res.message.forEach(function (item) {
                    if(item.type === 'IMGSIZE'){
                        vm.imgSize = item.value;
                    }
                    if(item.type === 'IMGDPI'){
                        vm.imgDpi = item.value;
                    }
                })
            }
        });


        //生成缩略图
        vm.thumbnailScale = 0.4;
        function thumbnailInit(callback){
            thumbnailInit1(function (res){
                if(!res){
                    vm.article.covered=false;
                    vm.article.cover=null;
                }else{
                    vm.article.covered=true;
                    vm.article.cover = res;
                }
                callback(res);
            })
        }
        function thumbnailInit1(callback) {
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view1'), {
                    onrendered: function (canvas) {
                        if(!canvas){
                            callback();
                            return
                        }
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        console.log(blob)
                        if(!blob){
                            callback();
                            return
                        }
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                var identifier = data.key;
                                callback(identifier)
                            }
                        })
                    },
                    error:function(){
                        if (callback) {callback()};
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $scope.template.pages.page[0].custom.width * $scope.template.pages.page[0].custom.thumbnailScale,
                    height: $scope.template.pages.page[0].custom.height * $scope.template.pages.page[0].custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            })
        }
        // 获取数量
        $scope.getQuantity=function(){
            var quantity=0;
            vm.data.forEach(function (item) {
                quantity += item.quantity
            })
            return quantity
        }

        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("ws://localhost:8096/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if ((vm.folderOne && vm.folderOne.id == photo.folderId) || (photo.folderId == null && !vm.folderOne)) {
                    var isHave = false;
                    for (var i = 0; i < vm.photoAll.length; i++) {
                        var item = vm.photoAll[i];
                        if (item.id == photo.id) {
                            isHave = true;
                            break;
                        }
                    }
                    if (!isHave) {
                        photo.identifier = $scope.domain + photo.identifier;
                        vm.photoAll.unshift(photo);
                        getPagePhoto();
                    }
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
        /*******************************************点击图片库*************************************************************************/
        vm.mapDepot = false;
        $scope.photoGalleryShow = function () {
            vm.mapDepot = true;
            vm.mapDepot1 = true;
            vm.mapDepot2 = false;
            updatePic()
            photoSelectAllInit();
        }
        $scope.photoGalleryHide = function () {
            vm.mapDepot = false;
        }
        $('#photoGallery').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.photoCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++
                    loadAll("isNull")
                }
            }
        })
        var dragGal = {};
        var drag = false;
        var startChangeTimeout;
        vm.isSmallPic = false;
        window.startChange = function (e) {
            startChangeTimeout = setTimeout(function() {
                vm.isSmallPic = true;
                $scope.$digest();
            }, 200);
            drag = true;
            var i = e.target.dataset.imgindex;
            if(i >=0){
                if(vm.mapDepot1){
                    $scope.galleryArr = vm.photoSelectAll1[i];
                    $scope.galleryChoose = vm.photoSelectAll1[i].identifier;
                }
                if(vm.mapDepot2){
                    $scope.galleryArr = vm.photoAll[i];
                    $scope.galleryChoose = vm.photoAll[i].identifier;
                }

                $scope.galleryId = i;
                $scope.galleryX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX - 53 : 0;
                $scope.galleryY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY - 40 : 0;
                dragGal.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
                dragGal.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
                dragGal.pageX = e.changedTouches[0].pageX ? e.changedTouches[0].pageX : 0;
                dragGal.pageY = e.changedTouches[0].pageY ? e.changedTouches[0].pageY : 0;
                dragGal.coordX = $scope.galleryX;
                dragGal.coordY = $scope.galleryY;
                $scope.$digest();
                return false;
            }
        };

        window.dragChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (drag) {
                //手指离开屏幕
                $scope.galleryX = (dragGal.coordX + (e.changedTouches[0].clientX - dragGal.clientX));
                $scope.galleryY = (dragGal.coordY + (e.changedTouches[0].clientY - dragGal.clientY));

                // if (Math.abs($scope.galleryY - dragGal.coordY) > 100) {
                //     vm.isSmallPic = true;
                // }


                //向上滑动;
                if ($scope.galleryY <= 0) {
                    $scope.galleryY = 0;
                }
                //向左滑动;        <-----
                if ($scope.galleryX <= 0) {
                    $scope.galleryX = 0;
                }
                //向下滑动;
                if ($scope.galleryY >= $scope.dHeight - 62) {
                    $scope.galleryY = $scope.dHeight - 62;
                }
                // 向右滑动;    ---->
                if ($scope.galleryX >= $scope.dWidth - 62) {
                    $scope.galleryX = $scope.dWidth - 62;
                }

                var _picTop = $("#small-pic").offset().top;
                var _picLeft = $("#small-pic").offset().left;
                var _exist = false;
                for (var i1 = vm.singleData[vm.singleIndex].levels.level.length-1; i1 >= 0; i1--) {
                    var _level = vm.singleData[vm.singleIndex].levels.level[i1];
                    if (_level.imagebox) {
                        var _imageboxLeft = $(".imagebox" + i1).offset().left;
                        var _imageboxTop = $(".imagebox" + i1).offset().top;
                        var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                        var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                        if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight) && !_exist) {
                            _exist = true;
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(103, 106, 108, 0.8)")
                            break;
                        }else{
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(0,0,0,0.2)")
                        }
                    }
                }
                $scope.$digest();
                return false;
            }
        };
        window.stopChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (!drag) {
                return
            }
            drag = false;
            var _picTop = $("#small-pic").offset().top;
            var _picLeft = $("#small-pic").offset().left;
            for (var i1 = vm.singleData[vm.singleIndex].levels.level.length-1; i1 >= 0; i1--) {
                var _level = vm.singleData[vm.singleIndex].levels.level[i1];
                if (_level.imagebox) {
                    var _imageboxLeft = $(".imagebox" + i1).offset().left;
                    var _imageboxTop = $(".imagebox" + i1).offset().top;
                    var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                    var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                    if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight)) {
                        $scope.replaceImagebox1($scope.galleryArr, i1)
                        break;
                    }
                }
            }
            vm.isSmallPic = false;
            $scope.$digest();
        };

        $scope.replaceImagebox1 = function (data, index) {
            vm.levelIndex = index;
            replaceImagebox(data)
        }

        function replaceImagebox(data, callback) {
            var item = angular.copy(data);
            var _imagebox = vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox;
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                resourceid:item.id,
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                imageInfo: item.imageInfo,
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0
            }
            _imagebox.blurredrectangle = {
                width: '',
                height: '',
                x: '',
                y: '',
            };
            getBlurredrectangle(_imagebox, $scope.zfInit ? 1 : 0);
        }
        function photoSelectAllInit() {
            vm.photoSelectAll1 = [];
            if(vm.singleData){
                vm.singleData.forEach(function (page) {
                    page.levels.level.forEach(function (level) {
                        if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                            var photo = {
                                identifier:level.imagebox.image.resource.identifier,
                                width:level.imagebox.image.imgWidth,
                                height:level.imagebox.image.imgHeight,
                                imgSize:level.imagebox.image.size,
                                imageInfo:level.imagebox.image.imageInfo,
                                resourceid:level.imagebox.image.resourceid,
                                id:level.imagebox.image.resourceid
                            }
                            var _is = false;
                            for(var i=0; i<vm.photoSelectAll1.length; i++){
                                if(vm.photoSelectAll1[i].id == level.imagebox.image.resourceid){
                                    _is = true;
                                    break
                                }
                            }
                            if(!_is){
                                vm.photoSelectAll1.push(photo)
                            }
                        }
                    })
                })
            }
            getFrequency();
        }
        function getFrequency(){
            if(vm.photoSelectAll1&&vm.photoSelectAll1.length>0){
                for(var i=0; i<vm.photoSelectAll1.length; i++){
                    vm.photoSelectAll1[i].frequency = 0;
                    if(vm.singleData){
                        vm.singleData.forEach(function (page) {
                            page.levels.level.forEach(function (level) {
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                    if(vm.photoSelectAll1[i].id == level.imagebox.image.resourceid){
                                        vm.photoSelectAll1[i].frequency = (vm.photoSelectAll1[i].frequency || 0) + 1;
                                    }
                                }
                            })
                        })
                    }
                }
            }
            if(vm.photoAll&&vm.photoAll.length>0){
                vm.photoAll.forEach(function (photoAll){
                    photoAll.frequency = 0;
                    if(vm.singleData){
                        vm.singleData.forEach(function (page) {
                            page.levels.level.forEach(function (level) {
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                    if(photoAll.id == level.imagebox.image.resourceid){
                                        photoAll.frequency = (photoAll.frequency || 0) + 1;
                                    }
                                }
                            })
                        })
                    }
                })
            }
        }

        $scope.addImageboxImg = function (item) {
            if(vm.singleData && vm.singleData[vm.singleIndex] && vm.singleData[vm.singleIndex].levels.level && vm.singleData[vm.singleIndex].levels.level[vm.levelIndex] && vm.singleData[vm.singleIndex].levels.level[vm.levelIndex].imagebox){
                replaceImagebox(item)
            }
        };
        /*******************************************点击图片库end*************************************************************************/
        $scope.editorPhoto1Head = function (){
            var top = 0;
            if((!vm.store.orderWay || vm.store.orderWay == 'editorThenOrder') && !vm.aid && vm.finalArray.length>1){
                top += 50;
            }
            if(vm.messageList && vm.messageShow){
                top += 31;
            }
            return top + 'px'
        }
        $scope.saveClass = function (){
            var _save = false;
            getCostMethod();
            if(vm.placeOrder){
                if(vm.store.orderWay == 'orderThenEditor' && vm.data.length>0){
                    if(vm.totalCount == vm.productLine.quantity){
                        _save = true
                    }
                }
                if(vm.store.orderWay == 'editorThenOrder'){
                    if(vm.productActiveSku.jhiPackage){
                        if(vm.totalCount % vm.productActiveSku.packageQuantity == 0){
                            _save = true
                        }
                    }else{
                        if(vm.data.length>0){
                            _save = true
                        }
                    }
                }
            }
            return _save
        }
    }
})();
