(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductThreeController', ProductThreeController)
        .directive('imageLazyLoad', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                }
            };
        });

    ProductThreeController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder', 'MessageNotification',
        'ThemeClassify', 'entity', 'Product', 'ProductXml', 'DateUtils', 'Iphone','SalesOrder','AuthServerProvider','FilterOrderRecords', 'CustomerUploadAble','Customer',
        'StoreConfig','MessageService', '$websocket'];

    function ProductThreeController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder, MessageNotification,
        ThemeClassify, entity, Product, ProductXml, DateUtils, Iphone, SalesOrder, AuthServerProvider, FilterOrderRecords, CustomerUploadAble,Customer,
        StoreConfig,MessageService, $websocket) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});

        var vm = this;
        //区别小程序、公众号
        // if (window.location.href.indexOf("?") > -1) {
        //     miniProgramUrl($localStorage, window.location.href);
        // }

        localStorage.removeItem('flag');
        if (window.location.href.indexOf("?") > -1) {
            localStorage.setItem('themeFlag', 'two');
            // localStorage.removeItem('guide-step');
            var r = window.location.href.split('?')[1];
            var typeFlag = r.split('&')[0].split('=')[1];
            var authorize = r.split('&')[1].split('=')[1];
            var productionType = r.split('&')[2].split('=')[1];
            var formLibrary = r.split('&')[3].split('=')[1];
            var orderOrigin = null;
            if (r.split('&').length>=5) {
                orderOrigin = r.split('&')[4].split('=')[1];
            }

            orderOrigin = decodeURIComponent(orderOrigin);
            var distinguish = decodeURIComponent(typeFlag);
            var tempAuthorization = decodeURIComponent(authorize);
            var authorization = tempAuthorization.slice(7, tempAuthorization.length);
            var showFlag = decodeURIComponent(productionType);
            var editLibrary = decodeURIComponent(formLibrary);
            if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake' || distinguish == 'toutiaoApp' || distinguish == 'alipayApp' || distinguish == 'jdApp') {
                vm.uploadType = distinguish;
                localStorage.setItem('orderOrigin', orderOrigin);
                localStorage.setItem('distinguish', distinguish);
                $localStorage.authenticationToken = authorization;
                var workSave = JSON.parse(localStorage.getItem("work-save"));
                var pid = editLibrary.split(',')[0];
                var vid = editLibrary.split(',')[1];
                var xmlId = editLibrary.split(',')[2];
                if (workSave == null) {
                    var obj = {
                        pid: pid,
                        vid: vid,
                        xmlId:xmlId,
                        date: new Date().getTime()
                    };
                    localStorage.setItem("work-save", JSON.stringify(obj));
                    localStorage.removeItem("pages");
                    localStorage.removeItem("gallery");
                    localStorage.removeItem("galleryCopy");
                } else {
                    var currentDate = new Date().getTime();
                    var flag = (currentDate - workSave.date) > 3 * 3600 * 1000 ? true : false;
                    if (workSave.pid != pid || workSave.vid != vid || flag) {
                        localStorage.removeItem("pages");
                        localStorage.removeItem("gallery");
                        localStorage.removeItem("galleryCopy");
                        var obj = {
                            pid: pid,
                            vid: vid,
                            xmlId:xmlId,
                            date: new Date().getTime()
                        };
                        localStorage.setItem("work-save", JSON.stringify(obj));
                    }
                }
            }
            vm.target=getQueryVariable('target');
            localStorage.setItem('target', vm.target);
            vm.flag=getQueryVariable('flag');
            localStorage.setItem('flag', vm.flag);
        }
        var isWx = false;
        var browser = CommonService.browserVersions();
        if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
            var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //在微信中打开
                isWx = true;
            }else if(browser.versions.android){
                //是否在安卓浏览器打开
                isWx = false;
            }
            if (ua.match(/WeiBo/i) == "weibo") {
                //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) == "qq") {
                //在QQ空间打开
            }
            if (browser.versions.ios) {
                //是否在IOS浏览器打开
                isWx = false;
            }
        } else {
            //否则就是PC浏览器打开
        }
        vm.isIPhone6 = isIPhone6();
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.salesOrderId = $stateParams.soid ? $stateParams.soid : null;
        vm.productLineId = $stateParams.plid ? $stateParams.plid : null;
        vm.counts = 0;
        vm.selectImg = [];
        vm.indexNum = 0;
        vm.maxId = null;
        vm.itemsPerPage = 20;
        vm.photoAll = [];
        $scope.domains = Qinius.getDomain1();
        vm.tokenTime = 360000;
        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });
        vm.platformSetting = PlatformWechatSetting.get();
        vm.photoArray = [];
        vm.photoSelectAll = localStorage.getItem("photoSelectAll") ? JSON.parse(localStorage.getItem("photoSelectAll")) : [];
        vm.isPhone = false;
        vm.threeIsModal = false;
        vm.threeModalText = "";
        vm.threeLoading = false;
        vm.threeLoadingText='加载中...';
        vm.threeError = false;
        vm.threeErrorText="加载中";
        vm.store = Store.get({id: vm.storeId});
        vm.customer = Customer.current();
        vm.diyEditorSetting={
            templateShow: true,//模板
            templateIcon: 'https://resources.momentsgocdn.com/gallery-5ae458bb-3254-4b9b-93dc-9caa0838a3ab',
            templateText:'模板',
            templateColor:'#333',
            clipartShow: true,//素材
            clipartIcon: 'https://resources.momentsgocdn.com/gallery-9fe28d31-ce19-4a77-bda9-c0e48b55059d',
            clipartText:'素材',
            clipartColor:'#333',
            wordShow: true,//文字
            wordIcon: 'https://resources.momentsgocdn.com/gallery-9cf32f4b-46f2-4679-d454-55b11d66f8ba',
            wordText:'文字',
            wordColor:'#333',
            imgShow: true,//图片
            imgIcon: 'https://resources.momentsgocdn.com/gallery-09babd73-2e43-4c06-c4d6-f181f313d7c2',
            imgText:'图片',
            imgColor:'#333',
            photoShow: true,//图片框
            photoIcon: 'https://resources.momentsgocdn.com/v2/qn-photoshape.png',
            photoText:'添加',
            photoColor:'#333',
            graffitiShow: true,//涂鸦
            graffitiIcon: 'https://resources.momentsgocdn.com/v2/qn-photoshape.png',
            graffitiText:'涂鸦',
            graffitiColor:'#333',
            changeImgSelf: true,//换图
            changeImgIcon: 'https://resources.momentsgocdn.com/gallery-4d1f90d1-6c58-430d-abbb-2582c5eb24af',
            changeImgText:'换图',
            changeImgColor:'#000',
            filterShow: true,//滤镜显示
            filterIcon: 'https://resources.momentsgocdn.com/gallery-1b8c1b12-bbe1-47da-f73c-b42b5b1c8aaa',
            filterText:'滤镜',
            filterColor:'#000',
            rotationSelf: true,//旋转
            rotationIcon: 'https://resources.momentsgocdn.com/gallery-22f21c70-d5dc-4530-d00c-47fc34e4fb4d',
            rotationText:'旋转',
            rotationColor:'#000',
            upSelf: true,//放大
            upIcon: 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2',
            upText:'放大',
            upColor:'#000',
            downSelf: true,//缩小
            downIcon: 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555',
            downText:'缩小',
            downColor:'#000',
            flipSelf: true,//翻转
            flipIcon: 'https://resources.momentsgocdn.com/gallery-7c05fe8d-31c8-4fae-8b31-9250e777d75e',
            flipText:'翻转',
            flipColor:'#000',
            restoreSelf: true,//还原
            restoreIcon: 'https://resources.momentsgocdn.com/gallery-a1fd5b5d-ccd9-488f-8951-2753c6a1aa4c',
            restoreText:'还原',
            restoreColor:'#000',
            spreadSelf: true,//展开
            spreadIcon: 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2',
            spreadText:'适应尺寸',
            spreadColor:'#000',
            retractSelf: true,//缩回
            retractIcon: 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555',
            retractText:'适应图片',
            retractColor:'#000',
            editWordIconSelf: true,//编辑文字
            editWordIcon: 'https://resources.momentsgocdn.com/gallery-5e70fa05-5a5d-4e9d-ab4f-1699c6877940',
            editWordText:'编辑文字',
            editWordColor:'#0a0a0a',
            wordStyleIconSelf: true,//文字样式
            wordStyleIcon: 'https://resources.momentsgocdn.com/gallery-581f0375-e252-4c0f-ee79-ef69e4dfb1f9',
            wordStyleText:'文字样式',
            wordStyleColor:'#0a0a0a',
            delWordIconSelf: true,//删除文字
            delWordIcon: 'https://resources.momentsgocdn.com/gallery-5072c751-ca0a-4b20-be05-bd61e8910176',
            delWordText:'删除文字',
            delWordColor:'#0a0a0a',
            upButtonSelf: true, //放大按钮
            upButtonIcon: 'https://resources.momentsgocdn.com/gallery-7c6b81c0-5515-4b6e-994c-0d2da6e4606a',
            downButtonSelf: true, //缩小按钮
            downButtonIcon: 'https://resources.momentsgocdn.com/gallery-fde0cdea-8c55-41e7-fcc1-819c97b9739e',
            mainColor: '#ff6e33', //主色调
            templateSeq: 1,
            clipartSeq: 2,
            wordSeq: 3,
            photoSeq: 5,
            imgSeq: 4,
            graffitiSeq: 6,
        };
        vm.isGuidePage = true;
        vm.isGuidePage1 =  false;
        vm.guideVersions = '1.0.0';
        if(localStorage.getItem("guide-editor-all") == vm.guideVersions){
            vm.isGuidePage1 = false;
        }else{
            vm.isGuidePage1 =  true;
            localStorage.setItem("guide-editor-all", vm.guideVersions);
            vm.guidePage = 1;
        }

        // 成功多少张
        var indd = 0;
        var errorImageArr = [];
        var initValue = 0;


        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };
        /**
         * wechat api
         */
        if (wx) {
            var url = "";
            if (!!window.location.hash ) {
                url = window.location.href.slice(0, -window.location.hash.length)
            }else{
                url =  window.location.href;
            }
            WxJS.get({url: url}, function (config) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: config.appId, // 必填，公众号的唯一标识
                    timestamp: config.timestamp, // 必填，生成签名的时间戳
                    nonceStr: config.nonceStr, // 必填，生成签名的随机串
                    signature: config.signature,// 必填，签名，见附录1
                    jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(function () {

                    wx.hideMenuItems({
                        menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                    });
                });
                wx.error(function (res) {
                });
            }, function (res) {
            });
        }

        init()

        function init() {
            if (!vm.isEdit) {
                getProduct(vm.pid,function () {
                    vm.page8 = true;
                    ItemValue.get({id: $stateParams.vid}, function (res) {
                        $("body").toggleClass('bg');
                        $scope.status = localStorage.getItem('Production');
                        angular.forEach(res.productXmlLines, function (line) {
                            if (line.id == vm.xmlId) {
                                vm.productXmlLine = angular.copy(line);
                                vm.modelBlendentUuid=vm.productXmlLine.modelBlendentUuid;
                            }
                        })
                        vm.article = {
                            modelBlendentUuid: vm.productXmlLine.modelBlendentUuid,
                            cover: vm.productXmlLine.coverImg,
                            themeClassifyId: vm.productXmlLine.productXmlThemeId,
                            themeClassifyName: vm.productXmlLine.productXmlThemeName,
                            productXmlId: vm.productXmlLine.productXmlId,
                            productXmlName: vm.productXmlLine.productXmlName,
                            musicIdentify: vm.productXmlLine ? vm.productXmlLine.musicIdentify : res.musicIdentify,
                            sku: res.sku,
                            skuNumber: res.skuNumber,
                            name: res.productName,
                            productId: res.productId,
                            productName: res.productName,
                            categoryId: res.categoryId,
                            categoryName: res.categoryName,
                            categoryStoreId: res.categoryStoreId,
                            categoryStoreName: res.categoryStoreName,
                            editorType: res.productEditorType,
                            productBokeh: res.productBokeh,
                            productBokehType: res.productBokehType || 'None',
                            itemValueId: res.itemValueId || vm.vid
                        };
                        if (!res.productBokeh) {
                            vm.article.bokehtype = 'None';
                        } else {
                            vm.article.bokehtype = res.productBokehType || 'None';
                        }

                        Convert.xmlToDocument2({
                            identifier: vm.productXmlLine ? vm.productXmlLine.productXmlIdentifier : res.productXmlIdentifier,
                            provider: "qiniu",
                            editorType:vm.productXmlLine.productXmlVersion == "4.0" ? "" : vm.article.editorType,
                            patternType:vm.product.patternType,
                        }, function (res) {
                            vm.counts = 0;
                            $scope.pages = res;
                            angular.forEach(res.pages.page, function (page, i) {
                                if(!page.backup) {
                                    angular.forEach(page.levels.level, function (level) {
                                        if (level.imagebox && level.imagebox.geometry) {
                                            vm.counts++;
                                        }
                                    })
                                }
                            });
                            abook();
                        });
                    });
                });
            } else {
                vm.page8 = true;
                Article.get({id: $stateParams.aid}, function (res) {
                    vm.article = res;
                    getProduct(vm.article.productId)
                    angular.forEach(res.document.pages.page, function (page, i) {
                        if(!page.backup) {
                            angular.forEach(page.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.geometry) {
                                    vm.counts++;
                                }
                            })
                        }
                    });
                    abook();
                })
            }
            vm.page4 = true;
            vm.page5 = false;

            $scope.qiniuId = localStorage.getItem("gallery") ? JSON.parse(localStorage.getItem("gallery")) : [];
            getByStere();
        }

        function getByStere(){
            // vm.storeConig = StoreConfig.getAllByTypes({storeId:vm.storeId, type:['IMGSHOW','PCSHOWIMG']})
            FilterOrderRecords.byStore({}, function (res) {
                vm.filterData = res;
            })
        }

        vm.filterInit = function(item){
            var isReturn = false;
            if(item == 'filter'){
                this.filterData.forEach(function(filterItem){
                    if((filterItem.filterType == 'IMAGESTYLECONVERT' || filterItem.filterType == 'PORTRAITCARTOON') && filterItem.usableTimes>0){
                        isReturn = true;
                    }
                })
            }
            if(item == 'portrait'){
                this.filterData.forEach(function(filterItem){
                    if(filterItem.filterType == 'SEGMENTATION' && filterItem.usableTimes>0){
                        isReturn = true;
                    }
                })
            }
            return isReturn
        };

        vm.fontList = [];
        function getProduct(productId,callback) {
            Product.get({id: productId, cancelled: false ,fixXMLLine:true}, function (res) {

                vm.product = res;
                if(vm.product.editorType == 'DIY_EDITOR'){
                    vm.diyEditorSetting = vm.product.diyEditorSetting ? vm.product.diyEditorSetting : vm.diyEditorSetting;
                    if(vm.diyEditorSetting.templateSelf){
                        vm.diyEditorSetting.templateIcon = vm.diyEditorSetting.templateIcon || 'https://resources.momentsgocdn.com/gallery-5ae458bb-3254-4b9b-93dc-9caa0838a3ab';
                    }else{
                        vm.diyEditorSetting.templateIcon = 'https://resources.momentsgocdn.com/gallery-5ae458bb-3254-4b9b-93dc-9caa0838a3ab';
                    }
                    vm.diyEditorSetting.templateText = vm.diyEditorSetting.templateText || '模板';
                    vm.diyEditorSetting.templateColor = vm.diyEditorSetting.templateColor || '#333';

                    if(vm.diyEditorSetting.clipartSelf){
                        vm.diyEditorSetting.clipartIcon = vm.diyEditorSetting.clipartIcon || 'https://resources.momentsgocdn.com/gallery-9fe28d31-ce19-4a77-bda9-c0e48b55059d';
                    }else{
                        vm.diyEditorSetting.clipartIcon = 'https://resources.momentsgocdn.com/gallery-9fe28d31-ce19-4a77-bda9-c0e48b55059d';
                    }
                    vm.diyEditorSetting.clipartText = vm.diyEditorSetting.clipartText || '素材';
                    vm.diyEditorSetting.clipartColor = vm.diyEditorSetting.clipartColor || '#333';
                    if(vm.diyEditorSetting.wordIconSelf){
                        vm.diyEditorSetting.wordIcon = vm.diyEditorSetting.wordIcon || 'https://resources.momentsgocdn.com/gallery-9cf32f4b-46f2-4679-d454-55b11d66f8ba';
                    }else{
                        vm.diyEditorSetting.wordIcon = 'https://resources.momentsgocdn.com/gallery-9cf32f4b-46f2-4679-d454-55b11d66f8ba';
                    }
                    vm.diyEditorSetting.wordText = vm.diyEditorSetting.wordText || '文字';
                    vm.diyEditorSetting.wordColor = vm.diyEditorSetting.wordColor || '#333';

                    if(vm.diyEditorSetting.photoSelf){
                        vm.diyEditorSetting.photoIcon = vm.diyEditorSetting.photoIcon || 'https://resources.momentsgocdn.com/v2/qn-photoshape.png';
                    }else{
                        vm.diyEditorSetting.photoIcon = 'https://resources.momentsgocdn.com/v2/qn-photoshape.png';
                    }
                    if(vm.diyEditorSetting.imgSelf){
                        vm.diyEditorSetting.imgIcon = vm.diyEditorSetting.imgIcon || 'https://resources.momentsgocdn.com/gallery-09babd73-2e43-4c06-c4d6-f181f313d7c2';
                    }else{
                        vm.diyEditorSetting.imgIcon = 'https://resources.momentsgocdn.com/gallery-09babd73-2e43-4c06-c4d6-f181f313d7c2';
                    }
                    vm.diyEditorSetting.imgText = vm.diyEditorSetting.imgText || '图片';
                    vm.diyEditorSetting.imgColor = vm.diyEditorSetting.imgColor || '#333';

                    if(vm.diyEditorSetting.changeImgSelf){
                        vm.diyEditorSetting.changeImgIcon = vm.diyEditorSetting.changeImgIcon || 'https://resources.momentsgocdn.com/gallery-4d1f90d1-6c58-430d-abbb-2582c5eb24af';
                    }else{
                        vm.diyEditorSetting.changeImgIcon = 'https://resources.momentsgocdn.com/gallery-4d1f90d1-6c58-430d-abbb-2582c5eb24af';
                    }
                    vm.diyEditorSetting.changeImgText = vm.diyEditorSetting.changeImgText || '换图';
                    vm.diyEditorSetting.changeImgColor = vm.diyEditorSetting.changeImgColor || '#000';

                    if(vm.diyEditorSetting.filterSelf){
                        vm.diyEditorSetting.filterIcon = vm.diyEditorSetting.filterIcon || 'https://resources.momentsgocdn.com/gallery-1b8c1b12-bbe1-47da-f73c-b42b5b1c8aaa';
                    }else{
                        vm.diyEditorSetting.filterIcon = 'https://resources.momentsgocdn.com/gallery-1b8c1b12-bbe1-47da-f73c-b42b5b1c8aaa';
                    }
                    vm.diyEditorSetting.filterText = vm.diyEditorSetting.filterText || '滤镜';
                    vm.diyEditorSetting.filterColor = vm.diyEditorSetting.filterColor || '#000';

                    if(vm.diyEditorSetting.rotationSelf){
                        vm.diyEditorSetting.rotationIcon = vm.diyEditorSetting.rotationIcon || 'https://resources.momentsgocdn.com/gallery-22f21c70-d5dc-4530-d00c-47fc34e4fb4d';
                    }else{
                        vm.diyEditorSetting.rotationIcon = 'https://resources.momentsgocdn.com/gallery-22f21c70-d5dc-4530-d00c-47fc34e4fb4d';
                    }
                    vm.diyEditorSetting.rotationText = vm.diyEditorSetting.rotationText || '旋转';
                    vm.diyEditorSetting.rotationColor = vm.diyEditorSetting.rotationColor || '#000';

                    if(vm.diyEditorSetting.upSelf){
                        vm.diyEditorSetting.upIcon = vm.diyEditorSetting.upIcon || 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2';
                    }else{
                        vm.diyEditorSetting.upIcon = 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2';
                    }
                    vm.diyEditorSetting.upText = vm.diyEditorSetting.upText || '放大';
                    vm.diyEditorSetting.upColor = vm.diyEditorSetting.upColor || '#000';

                    if(vm.diyEditorSetting.downSelf){
                        vm.diyEditorSetting.downIcon = vm.diyEditorSetting.downIcon || 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555';
                    }else{
                        vm.diyEditorSetting.downIcon = 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555';
                    }
                    vm.diyEditorSetting.downText = vm.diyEditorSetting.downText || '缩小';
                    vm.diyEditorSetting.downColor = vm.diyEditorSetting.downColor || '#000';

                    if(vm.diyEditorSetting.flipSelf){
                        vm.diyEditorSetting.flipIcon = vm.diyEditorSetting.flipIcon || 'https://resources.momentsgocdn.com/gallery-7c05fe8d-31c8-4fae-8b31-9250e777d75e';
                    }else{
                        vm.diyEditorSetting.flipIcon = 'https://resources.momentsgocdn.com/gallery-7c05fe8d-31c8-4fae-8b31-9250e777d75e';
                    }
                    vm.diyEditorSetting.flipText = vm.diyEditorSetting.flipText || '翻转';
                    vm.diyEditorSetting.flipColor = vm.diyEditorSetting.flipColor || '#000';

                    if(vm.diyEditorSetting.restoreSelf){
                        vm.diyEditorSetting.restoreIcon = vm.diyEditorSetting.restoreIcon || 'https://resources.momentsgocdn.com/gallery-a1fd5b5d-ccd9-488f-8951-2753c6a1aa4c';
                    }else{
                        vm.diyEditorSetting.restoreIcon = 'https://resources.momentsgocdn.com/gallery-a1fd5b5d-ccd9-488f-8951-2753c6a1aa4c';
                    }
                    vm.diyEditorSetting.restoreText = vm.diyEditorSetting.restoreText || '还原';
                    vm.diyEditorSetting.restoreColor = vm.diyEditorSetting.restoreColor || '#000';

                    if(vm.diyEditorSetting.spreadSelf){
                        vm.diyEditorSetting.spreadIcon = vm.diyEditorSetting.spreadIcon || 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2';
                    }else{
                        vm.diyEditorSetting.spreadIcon = 'https://resources.momentsgocdn.com/gallery-584d87d7-c25a-46c3-cdc9-120d3e2f65d2';
                    }
                    vm.diyEditorSetting.spreadText = vm.diyEditorSetting.spreadText || '适应尺寸';
                    vm.diyEditorSetting.spreadColor = vm.diyEditorSetting.spreadColor || '#000';

                    if(vm.diyEditorSetting.retractSelf){
                        vm.diyEditorSetting.retractIcon = vm.diyEditorSetting.retractIcon || 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555';
                    }else{
                        vm.diyEditorSetting.retractIcon = 'https://resources.momentsgocdn.com/gallery-8834bf87-10ca-4922-a5ea-3e9bde06f555';
                    }
                    vm.diyEditorSetting.retractText = vm.diyEditorSetting.retractText || '适应图片';
                    vm.diyEditorSetting.retractColor = vm.diyEditorSetting.retractColor || '#000';

                    if(vm.diyEditorSetting.editWordIconSelf){
                        vm.diyEditorSetting.editWordIcon = vm.diyEditorSetting.editWordIcon || 'https://resources.momentsgocdn.com/gallery-5e70fa05-5a5d-4e9d-ab4f-1699c6877940';
                    }else{
                        vm.diyEditorSetting.editWordIcon = 'https://resources.momentsgocdn.com/gallery-5e70fa05-5a5d-4e9d-ab4f-1699c6877940';
                    }
                    vm.diyEditorSetting.editWordText = vm.diyEditorSetting.editWordText || '编辑文字';
                    vm.diyEditorSetting.editWordColor = vm.diyEditorSetting.editWordColor || '#0a0a0a';

                    if(vm.diyEditorSetting.wordStyleIconSelf){
                        vm.diyEditorSetting.wordStyleIcon = vm.diyEditorSetting.wordStyleIcon || 'https://resources.momentsgocdn.com/gallery-581f0375-e252-4c0f-ee79-ef69e4dfb1f9';
                    }else{
                        vm.diyEditorSetting.wordStyleIcon = 'https://resources.momentsgocdn.com/gallery-581f0375-e252-4c0f-ee79-ef69e4dfb1f9';
                    }
                    vm.diyEditorSetting.wordStyleText = vm.diyEditorSetting.wordStyleText || '文字样式';
                    vm.diyEditorSetting.wordStyleColor = vm.diyEditorSetting.wordStyleColor || '#0a0a0a';

                    if(vm.diyEditorSetting.delWordIconSelf){
                        vm.diyEditorSetting.delWordIcon = vm.diyEditorSetting.delWordIcon || 'https://resources.momentsgocdn.com/gallery-5072c751-ca0a-4b20-be05-bd61e8910176';
                    }else{
                        vm.diyEditorSetting.delWordIcon = 'https://resources.momentsgocdn.com/gallery-5072c751-ca0a-4b20-be05-bd61e8910176';
                    }
                    vm.diyEditorSetting.delWordText = vm.diyEditorSetting.delWordText || '删除文字';
                    vm.diyEditorSetting.delWordColor = vm.diyEditorSetting.delWordColor || '#0a0a0a';

                    vm.diyEditorSetting.upButtonIcon = vm.diyEditorSetting.upButtonIcon || 'https://resources.momentsgocdn.com/gallery-7c6b81c0-5515-4b6e-994c-0d2da6e4606a';

                    vm.diyEditorSetting.downButtonIcon = vm.diyEditorSetting.downButtonIcon || 'https://resources.momentsgocdn.com/gallery-fde0cdea-8c55-41e7-fcc1-819c97b9739e';
                    vm.diyEditorSetting.mainColor = vm.diyEditorSetting.mainColor || '#ff6e33';
                    if(vm.diyEditorSetting.wordIconSelf){
                        if(vm.diyEditorSetting.fontSupportSelf){
                            vm.diyEditorSetting.supportFonts.forEach(function (supportFont) {
                                vm.fontList.push(supportFont.fontManagement)
                            })
                        }else{
                            //获取所有字体
                            getAllFont();
                        }
                        if(vm.diyEditorSetting.fontColorSupportSelf){
                            $scope.colorList = [];
                            vm.diyEditorSetting.supportFontColors.forEach(function (supportFontColor) {
                                $scope.colorList.push(supportFontColor.color)
                            })
                        }
                    }else{
                        //获取所有字体
                        getAllFont();
                    }
                }else{
                    //获取所有字体
                    getAllFont();
                }
                if(vm.diyEditorSetting){
                    vm.diyEditorSetting.seqItem=[];
                    vm.diyEditorSetting.imgItems = [];
                    vm.diyEditorSetting.fontItems = [];
                    if(vm.diyEditorSetting.wordShow){
                        vm.diyEditorSetting.seqItem.push({
                            type:'word',
                            seq: vm.diyEditorSetting.wordSeq
                        })
                    }
                    if(vm.diyEditorSetting.templateShow){
                        vm.diyEditorSetting.seqItem.push({
                            type:'template',
                            seq: vm.diyEditorSetting.templateSeq
                        })
                    }
                    if(vm.diyEditorSetting.clipartShow){
                        vm.diyEditorSetting.seqItem.push({
                            type:'clipart',
                            seq: vm.diyEditorSetting.clipartSeq
                        })
                    }
                    if(vm.diyEditorSetting.photoShow){
                        vm.diyEditorSetting.seqItem.push({
                            type:'photo',
                            seq: vm.diyEditorSetting.photoSeq
                        })
                    }
                    if(vm.diyEditorSetting.imgShow){
                        vm.diyEditorSetting.seqItem.push({
                            type:'img',
                            seq: vm.diyEditorSetting.imgSeq
                        })
                    }
                    if(vm.diyEditorSetting.graffitiShow){
                        vm.diyEditorSetting.seqItem.push({
                            type:'graffiti',
                            seq: vm.diyEditorSetting.graffitiSeq
                        })
                    }
                    vm.diyEditorSetting.seqItem = vm.diyEditorSetting.seqItem.sort(sortItem("seq"));

                    //图片子菜单
                    vm.diyEditorSetting.imgItems.push({object: 'changeImgSeq', rightIndex: 'changeImg', text: vm.diyEditorSetting.changeImgText, color: vm.diyEditorSetting.changeImgColor, icon: vm.diyEditorSetting.changeImgIcon, self: vm.diyEditorSetting.changeImgSelf, seq: vm.diyEditorSetting.changeImgSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'portraitSeq', rightIndex: 'portrait', text: '抠人像', color: '#333', icon: 'iconbhjmianbushibie', seq: vm.diyEditorSetting.filterSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'filterSeq', rightIndex: 'filter', text: vm.diyEditorSetting.filterText, color: vm.diyEditorSetting.filterColor, icon: vm.diyEditorSetting.filterIcon, self: vm.diyEditorSetting.filterSelf, seq: vm.diyEditorSetting.filterSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'rotationSeq', rightIndex: 'rotation', text: vm.diyEditorSetting.rotationText, color: vm.diyEditorSetting.rotationColor, icon: vm.diyEditorSetting.rotationIcon, self: vm.diyEditorSetting.rotationSelf, seq: vm.diyEditorSetting.rotationSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'upSeq', rightIndex: 'up', text: vm.diyEditorSetting.upText, color: vm.diyEditorSetting.upColor, icon: vm.diyEditorSetting.upIcon, self: vm.diyEditorSetting.upSelf, seq: vm.diyEditorSetting.upSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'downSeq', rightIndex: 'down', text: vm.diyEditorSetting.downText, color: vm.diyEditorSetting.downColor, icon: vm.diyEditorSetting.downIcon, self: vm.diyEditorSetting.downSelf, seq: vm.diyEditorSetting.downSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'flipSeq', rightIndex: 'flip', text: vm.diyEditorSetting.flipText, color: vm.diyEditorSetting.flipColor, icon: vm.diyEditorSetting.flipIcon, self: vm.diyEditorSetting.flipSelf, seq: vm.diyEditorSetting.flipSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'restoreSeq', rightIndex: 'restore', text: vm.diyEditorSetting.restoreText, color: vm.diyEditorSetting.restoreColor, icon: vm.diyEditorSetting.restoreIcon, self: vm.diyEditorSetting.restoreSelf, seq: vm.diyEditorSetting.restoreSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'spreadSeq', rightIndex: 'spread', text: vm.diyEditorSetting.spreadText, color: vm.diyEditorSetting.spreadColor, icon: vm.diyEditorSetting.spreadIcon, self: vm.diyEditorSetting.spreadSelf, seq: vm.diyEditorSetting.spreadSeq});
                    vm.diyEditorSetting.imgItems.push({object: 'retractSeq', rightIndex: 'retract', text: vm.diyEditorSetting.retractText, color: vm.diyEditorSetting.retractColor, icon: vm.diyEditorSetting.retractIcon, self: vm.diyEditorSetting.retractSelf, seq: vm.diyEditorSetting.retractSeq});
                    vm.diyEditorSetting.imgItems.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                    vm.diyEditorSetting.fontItems.push({object: 'editWordSeq', rightIndex: 'editWord', text: vm.diyEditorSetting.editWordText, color: vm.diyEditorSetting.editWordColor, icon: vm.diyEditorSetting.editWordIcon, self: vm.diyEditorSetting.editWordIconSelf, seq: vm.diyEditorSetting.editWordSeq});
                    vm.diyEditorSetting.fontItems.push({object: 'wordStyleSeq', rightIndex: 'wordStyle', text: vm.diyEditorSetting.wordStyleText, color: vm.diyEditorSetting.wordStyleColor, icon: vm.diyEditorSetting.wordStyleIcon, self: vm.diyEditorSetting.wordStyleIconSelf, seq: vm.diyEditorSetting.wordStyleSeq});
                    vm.diyEditorSetting.fontItems.push({object: 'delWordSeq', rightIndex: 'delWord', text: vm.diyEditorSetting.delWordText, color: vm.diyEditorSetting.delWordColor, icon: vm.diyEditorSetting.delWordIcon, self: vm.diyEditorSetting.delWordIconSelf, seq: vm.diyEditorSetting.delWordSeq});
                    vm.diyEditorSetting.fontItems.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
                if(callback){callback()}
            })
        }

        $q.all([$scope.domains.$promise,vm.customer.$promise]).then(function () {
            if(!vm.userId){
                vm.userId = vm.customer.id
            }
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            setTimeout(function fn() {
                Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            //获取照片旋转角度使用
            //判断是ios or Android
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if(vm.uploadType=='alipayApp' || vm.uploadType == 'toutiaoApp'){
                $scope.agentIos = true;
            }else if(!isWx){
                $scope.agentIos = false;
            } else if (isAndroid == true && isiOS == false) {
                $scope.agentIos = true;
            } else if (isAndroid == false && isiOS == true) {
                $scope.agentIos = false;
            }

            //获取图片库
            loadAll('isNull');
            //消息列表
            getMessage();
            vm.isIPhoneX = Iphone;
            websocket();
        });

        $scope.uploadPhoto = function () {
            uploadVerify(vm.platformSetting, CustomerUploadAble, vm.storeId, vm.customer.id, function (res) {
                if($scope.agentIos){
                    $rootScope.$broadcast('clickAndroidUpload',true);
                }else{
                    $timeout(function () {
                        $("#uploadImageMutipleId").trigger("click");
                    })
                }
            })
        }
        vm.messageShow = true;
        //消息列表
        function getMessage() {
            vm.messageList = "";
            getMessageCustom(vm, DateUtils, MessageNotification, function (res) {
                res.forEach(function (item, index) {
                    vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                })
            })
        }


        $scope.getBack = function (item) {
            if (item == 'page5') {
                vm.page4 = true;
                vm.page5 = false;
            }
        }
        // $(window).scroll(function () {
        //     //向下滑动加载照片
        //     var scrollTop = $(window).scrollTop();
        //     var widH = $(window).height();
        //     var docH = $(document).height();
        //     if (scrollTop + widH == docH) {
        //         if(!vm.photoCompleted){
        //             vm.indexNum++;
        //             loadAll('isNull');
        //         }
        //     }
        // });


        $('.sticker-scroll').scroll(function () {
            if (!vm.photoCompleted) {
                var allHeight = $(this).find("ul").height() - $(this).height();
                if ($(this).scrollTop() >= allHeight) {
                    vm.indexNum++;
                    loadAll()
                }
            }
        });
        vm.uploadPhotoItem=[];
        $scope.$on('uoloadFinished', function(event, args) {
            if(args){
                angular.forEach(vm.photoAll,function (qin) {
                    if(qin.blobCover){
                        qin.blobCover = null;
                    }
                })
                // updatePic();
            }
        });
        $scope.$on('uoloadDuplicate', function(event, res) {
            updatePic();
        });
        // 上传图片后更新图片库/文件夹
        function updatePic() {
            vm.photoAll=[];
            vm.indexNum = 0;
            vm.maxId = null;
            vm.photoCompleted = false;
            loadAll();
        }
        function loadAll() {
            var items = [];
            items.push({key: "folderId", op: "isNull", value: ''});
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            Gallery.getPage({
                items: items,
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: sorts()
            }, onSuccess, onError);
        }

        function sorts() {
            return ["id,desc"];
        }

        function onSuccess(res) {
            if (vm.maxId == null && res.length > 0) {
                vm.maxId = res[0].id;
            }
            for (var i = 0; i < res.length; i++) {
                if (!res[i].cancelled) {
                    res[i].identifier = $scope.domain + res[i].identifier;
                    vm.photoAll.push(res[i])
                }
            }
            if (res.length < 20) {
                vm.photoCompleted = true;
            }
        }

        function onError(data) {
            MessageService.hint("网络错误，请切换网络重试");
        }


        //获取dpi
        js_getDPI(function (dpi) {
            vm.windowDpi = dpi;
        });
        vm.pageData = null;
        vm.pageIndex = 0;
        $scope.template = null;
        function abook() {
            productXmlGet()
            vm.page4 = true;
            vm.page8 = true;
            if (vm.isEdit) {
                $scope.template = angular.copy(vm.article.document);
                $scope.template.pages.page=[];
                vm.article.document.pages.page.forEach(function (page) {
                    if(!page.backup){
                        var _page = page;
                        _page.seq = $scope.template.pages.page.length;
                        $scope.template.pages.page.push(_page);
                    }
                })
            } else {
                $scope.template = angular.copy($scope.pages);
                $scope.template.pages.page=[];
                $scope.pages.pages.page.forEach(function (page) {
                    if(!page.backup){
                        var _page = page;
                        _page.seq = $scope.template.pages.page.length;
                        $scope.template.pages.page.push(_page);
                    }
                })
            }
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                ratioInfo(page)
            }, function () {
                vm.pageData = [];
                $timeout(function () {
                    vm.editProductXml = false;
                    vm.page8 = false;
                    vm.pageData = $scope.template.pages.page;
                    vm.pageSum = $scope.template.pages.page.length - 1;
                    vm.pageIndex = 0;
                    $timeout(function () {
                        thumbnailInit();
                    },100)
                }, 1000)
            })
        }
        function productXmlGet(){
            ProductXml.get({id:vm.article.productXmlId}, function (productXml) {
                vm.productXmlData = productXml;
            })
        }

        vm.isEditboxMagnify = false;
        vm.ratioInfo = ratioInfo;
        function ratioInfo(item) {
            vm.windowWidth = document.body.clientWidth;
            vm.windowHeight = document.body.clientHeight * 0.91 - 175;
            if(vm.isAddImagebox || vm.mapDepot || vm.isMaterial || (vm.istemplate && vm.page4)){
                vm.windowHeight = document.body.clientHeight - 280 - (document.body.clientHeight*0.02);
            }
            if(vm.isEditboxMagnify){
                var ratio = item.mediabox.width / item.mediabox.height;
                var ratio1 = vm.windowWidth / vm.windowHeight;
                if (ratio >= ratio1) {
                    item.custom.ratio1 = vm.windowWidth / item.mediabox.width;
                } else {
                    item.custom.ratio1 = vm.windowHeight / item.mediabox.height;
                }
            }else{
                var ratio = item.custom.width / item.custom.height;
                var ratio1 = vm.windowWidth / vm.windowHeight;
                if (ratio >= ratio1) {
                    item.custom.ratio1 = vm.windowWidth / item.custom.width;
                } else {
                    item.custom.ratio1 = vm.windowHeight / item.custom.height;
                }
            }
            item.custom.thumbnailScale = 300 / item.custom.width;
        }
        $scope.editboxMagnify = function () {
            vm.isEditboxMagnify = !vm.isEditboxMagnify;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        function warnIcon() {
            $timeout(function () {
                vm.warnIcon = false;
                vm.pageData[vm.pageIndex].levels.level.forEach(function (level) {
                    if(level.imagebox && level.imagebox.image &&level.imagebox.image.resource&&level.imagebox.image.resource.identifier && pxInsufficient(level.imagebox,vm.imgSize, vm.imgDpi)){
                        vm.warnIcon = true;
                    }
                })
            })
        }

        $scope.previousPage = function () {
            vm.pageIndex--;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        $scope.nextPage = function () {
            vm.pageIndex++;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }
        $scope.goPageContent = function (index) {
            vm.isDocumentTextDetection=false;
            vm.pageIndex = index;
            ratioInfo(vm.pageData[vm.pageIndex]);
        }

        /*******************************************点击模板*************************************************************************/
        $scope.templateShow = function () {
            vm.istemplate = true;
            productInit()
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.templateHide = function () {
            vm.istemplate = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }

        function productInit() {
            if(vm.isEdit){
                Product.get({id: vm.article.productId, cancelled: false ,fixXMLLine:true}, function (res) {
                    for (var i = 0; i < res.itemValues.length; i++) {
                        var itemValue = res.itemValues[i];
                        if (itemValue.cancelled) {
                            continue;
                        }
                        if (itemValue.id == vm.article.itemValueId) {
                            vm.tempList = angular.copy(itemValue);
                            productXmlInit()
                        }
                    }
                })
            }else{
                Product.get({id: vm.pid, cancelled: false ,fixXMLLine:true}, function (res) {
                    for (var i = 0; i < res.itemValues.length; i++) {
                        var itemValue = res.itemValues[i];
                        if (itemValue.cancelled) {
                            continue;
                        }
                        if (itemValue.id == vm.vid) {
                            vm.tempList = angular.copy(itemValue);
                            productXmlInit()
                        }
                    }
                })
            }
        };
        function productXmlInit(){
            vm.productXmlThemeId = '';
            vm.productXmls = [{id:'',name:'全部 ', seq:''}];
            vm.tempList.productXmlLines.forEach(function (temp) {
                if (temp.id && !temp.cancelled) {
                    temp.themeClassifies.forEach(function (themeClassifie) {
                        var isExist = false;
                        for(var i=0; i<vm.productXmls.length; i++){
                            if(vm.productXmls[i].id == themeClassifie.id){
                                isExist = true;
                                break;
                            }
                        }
                        if(!isExist){
                            vm.productXmls.push(themeClassifie)
                        }
                    })
                }
            })
            vm.productXmls.sort(function (a, b) {
                return a.seq - b.seq;
            });
        }
        $scope.productXmlCb = function(item){
            vm.productXmlThemeId = item.id;
        };
        $scope.productXmlShow = function(themeClassifies){
            var _exist = false;
            themeClassifies.forEach(function (themeClassifie) {
                if (vm.productXmlThemeId ==  themeClassifie.id) {
                    _exist = true;
                }
            })
            return _exist
        }

        vm.imgs = [];
        var templateSwiper = null;
        $scope.templateCb = function (temp) {
            if (temp.id == vm.xmlId || temp.id == vm.article.productXmlLineId) {
                vm.threeIsModal1 = true;
                vm.threeModalText1 = "已在当前模板，请选择其他模板！";
                return
            }
            vm.xmlShowPic = true;
            $scope.chooseTemp = temp;
            ProductXml.findOne({id: temp.productXmlId}, function (data) {
                if (data.documentPics && data.documentPics.length > 0) {
                    data.documentPics.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
                vm.imgs = data.documentPics;
                if(vm.imgs.length > 0){
                    $timeout(function () {
                        templateSwiperInit();
                        templateSwiper.init();//现在才初始化
                    },200)
                }
            }, function () {

            })
        }
        function templateSwiperInit() {
            templateSwiper = new Swiper ('.swiper-template-view-content', {
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            })
        }

        var inits = 0, num = 0;
        $scope.previewClose = function () {
            vm.xmlShowPic = false;
        };
        $scope.changeAllTemp = function () {
            vm.threeIsModal = true;
            vm.threeModalText = "更换模板后，除照片自动填充其余自动清除，是否更换？";
        }

        function templateReplace() {
            if(vm.isEdit){
                vm.pageData.forEach(function (page) {
                    if(page.levels.level){
                        page.levels.level.forEach(function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                vm.photoSelectAll.push({
                                    height: level.imagebox.image.resource.height,
                                    id: level.imagebox.image.resourceid,
                                    imageInfo: level.imagebox.image.imageInfo,
                                    identifier: level.imagebox.image.resource.identifier.indexOf('http') > -1 ? level.imagebox.image.resource.identifier : domain + level.imagebox.image.resource.identifier,
                                    imgsize: level.imagebox.image.imgsize,
                                    name: null,
                                    provider: "qiniu",
                                    usenum: 1,
                                    width: level.imagebox.image.resource.width
                                });
                            }
                        })
                    }
                })
                Convert.xmlToDocument2({
                    identifier: $scope.chooseTemp.productXmlIdentifier,
                    provider: "qiniu",
                    editorType:$scope.chooseTemp.productXmlVersion == "4.0" ? "" : vm.article.editorType,
                    patternType:vm.product.patternType,
                }, function (res) {
                    vm.counts = 0;
                    angular.forEach(res.pages.page, function (page, i) {
                        if(!page.backup) {
                            angular.forEach(page.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.geometry) {
                                    vm.counts++;
                                }
                            })
                        }
                    });
                    $scope.previewClose();
                    $scope.templateHide();
                    vm.article.document = res;

                    vm.productXmlLine = angular.copy($scope.chooseTemp);
                    vm.modelBlendentUuid=vm.productXmlLine.modelBlendentUuid;
                    vm.article.modelBlendentUuid= vm.productXmlLine.modelBlendentUuid;
                    vm.article.cover= vm.productXmlLine.coverImg;
                    vm.article.themeClassifyId= vm.productXmlLine.productXmlThemeId;
                    vm.article.themeClassifyName= vm.productXmlLine.productXmlThemeName;
                    vm.article.productXmlId= vm.productXmlLine.productXmlId;
                    vm.article.productXmlName= vm.productXmlLine.productXmlName;
                    vm.article.productXmlLineId= vm.productXmlLine.id;
                    if(vm.productXmlLine && vm.productXmlLine.musicIdentify){
                        vm.article.musicIdentify= vm.productXmlLine.musicIdentify;
                    }
                    vm.isHtml2canvas = false;
                    vm.editProductXml = true;
                    abook();
                });
            }else{
                vm.pageData.forEach(function (page) {
                    if(page.levels.level){
                        page.levels.level.forEach(function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                vm.photoSelectAll.push({
                                    height: level.imagebox.image.resource.height,
                                    id: level.imagebox.image.resourceid,
                                    imageInfo: level.imagebox.image.imageInfo,
                                    identifier: level.imagebox.image.resource.identifier.indexOf('http') > -1 ? level.imagebox.image.resource.identifier : domain + level.imagebox.image.resource.identifier,
                                    imgsize: level.imagebox.image.imgsize,
                                    name: null,
                                    provider: "qiniu",
                                    usenum: 1,
                                    width: level.imagebox.image.resource.width
                                });
                            }
                        })
                    }
                })
                window.location.href = vm.platformSetting.editorNotifyUrl+'/#/'+vm.storeId+'/'+vm.pid+'/'+vm.vid+'/'+$scope.chooseTemp.id+'/'+vm.salesOrderId+'/'+vm.productLineId+'/chooseThree';
                $timeout(function () {
                    // window.location.reload();
                    localStorage.setItem("photoSelectAll", JSON.stringify(vm.photoSelectAll));
                })
            }
        }

        /*******************************************点击图片库*************************************************************************/
        vm.mapDepot = false;
        $scope.photoGalleryShow = function () {
            vm.mapDepot = true;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.photoGalleryHide = function () {
            vm.mapDepot = false;
            vm.isAddImagebox = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $('#photoGallery').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.photoCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++
                    loadAll("isNull")
                }
            }
        });
        $scope.addImageboxCb = function(){
            vm.isAddImagebox = true;
            ratioInfo(vm.pageData[vm.pageIndex])
        };
        vm.isGraffiti = false;
        vm.oldGraffitiMagnify = false;
        $scope.graffitiCb = function () {
            vm.oldGraffitiMagnify = vm.isEditboxMagnify;
            if(!vm.isEditboxMagnify){
                $scope.editboxMagnify();
            }
            vm.isGraffiti = true;
            canvasInit()
        };
        function imageboxAddFn(item){

            imageboxAdd($http, item, vm.pageData[vm.pageIndex].trimbox, vm.article, function(imagebox){

                addImagebox($http, imagebox, function () {
                    var _level = {
                        seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                        imagebox: imagebox
                    }
                    vm.pageData[vm.pageIndex].levels.level.push(_level);
                    $timeout(function () {
                        if(!vm.isImageboxSelectItem){
                            $scope.makeBody();
                        }
                        vm.levelIndex = parseInt(vm.pageData[vm.pageIndex].levels.level.length-1);
                        vm.levelType = 'imagebox';
                        thumbnailInit();
                    });
                });
            })
        }

        /*******************************************素材处理*************************************************************************/
        $scope.makeBody = vm.makeBody = function (callback) {
            vm.levelIndex = null;
            vm.levelType = null;
            vm.isImageboxSelectItem = false;
            vm.textStyleShow = false;
            vm.textboxSelect = false;
            vm.filterShow = false;
            vm.mattingShow = false;
            if(vm.isAddImagebox || vm.mapDepot || vm.isMaterial || vm.istemplate){
                vm.istemplate = false;
                vm.isMaterial = false;
                vm.mapDepot = false;
                vm.isAddImagebox = false;
                ratioInfo(vm.pageData[vm.pageIndex]);
            }else {
                vm.istemplate = false;
                vm.isMaterial = false;
                vm.isAddImagebox = false;
            }
            if(callback){callback()}
        }
        vm.levelDome = function(item){
            vm.isImageboxSelectItem = false;
            vm.textStyleShow = false;
            vm.textboxSelect = false;
            vm.filterShow = false;
            vm.mattingShow = false;
            vm.mapDepot = false;
            vm.isAddImagebox = false;
            vm.istemplate = false;
            vm.isMaterial = false;
            if(item){
                vm[item] = true;
            }
        }

        vm.isMaterial = false;
        $scope.materialShow = function () {
            vm.isMaterial = true;
            vm.materialPage = 0;
            vm.materialSum = 20;
            vm.materialData = [];
            //分类
            ThemeClassify.getAllByCondition4({type: 'CLIPART', storeId: vm.storeId}, function (res) {
                vm.classify = [];
                if($scope.template.cliparts && $scope.template.cliparts.clipart && $scope.template.cliparts.clipart.length>0){
                    vm.classify = [{
                        id: "",
                        name: "推荐",
                        seq: 0,
                        type: "CLIPART",
                        used: true
                    }]
                }
                res.message.forEach(function (item) {
                    vm.classify.push(item)
                })
                vm.classifyW = res.message.length * 86;
                initMaterial(vm.classify[0].id)
            });
            ratioInfo(vm.pageData[vm.pageIndex])
        }
        $scope.classifyCb = function (item) {
            vm.materialPage = 0;
            vm.materialData = [];
            initMaterial(item.id)
        }
        //获取素材
        vm.isMaterial1 = false;

        function initMaterial(id) {
            vm.classifyId = id;
            if(id){
                ClipArt.byCondition3({
                    items: [
                        {key: "themeClassify.id", op: "=", value: id}],
                    page: vm.materialPage,
                    size: vm.materialSum
                }, function (res) {
                    angular.forEach(res, function (resp) {
                        var arr = {
                            name: resp.name,
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: $scope.domain + resp.identifier,
                                provider: resp.provider
                            }
                        }
                        vm.materialData.push(arr);
                    })
                    vm.materialW = vm.materialData.length * 90 + 20;
                    if (res.length < vm.materialSum) {
                        vm.isMaterial1 = true;
                    }else {
                        vm.isMaterial1 = false;
                    }
                });
            }else{
                $scope.template.cliparts.clipart.forEach(function (clipart) {
                    var arr = {
                        name: "",
                        identifier: clipart.resource.identifier,
                        provider: "",
                        themeClassifyName: "推荐",
                        themeClassifyId: "",
                        resource: clipart.resource
                    }
                    vm.materialData.push(arr);
                })
                vm.materialW = vm.materialData.length * 90 + 20;
                vm.isMaterial1 = true;
            }
        }
        $('#materialView').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.isMaterial1){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.materialPage++;
                    initMaterial(vm.classifyId)
                }
            }
        })
        $scope.materialHide = function () {
            vm.isMaterial = false;
            ratioInfo(vm.pageData[vm.pageIndex])
        }

        //添加素材
        vm.repeatAddNumber = 0;
        $scope.addImageart = function (item) {
            vm.threeLoading = true;
            vm.threeLoadingText='加载中...';
            imageartAdd($http, item, vm.pageData[vm.pageIndex].trimbox, function(imageart){
                if(vm.repeatAddNumber>4){
                    vm.repeatAddNumber = 0;
                }
                var _num = 10*vm.repeatAddNumber;
                imageart.geometry.x += _num/vm.pageData[vm.pageIndex].custom.ratio1;
                imageart.geometry.y += _num/vm.pageData[vm.pageIndex].custom.ratio1;
                var _level = {
                    seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                    imageart: imageart
                }
                vm.pageData[vm.pageIndex].levels.level.push(_level);
                thumbnailInit();
                vm.threeLoading = false;
                vm.repeatAddNumber++;
            },vm.pageData[vm.pageIndex].custom.ratio1)
        }
        //选中素材
        // $scope.imageartCb = function (e, data, index) {
        //     fn(e)
        //     vm.levelIndex = index;
        // };
        $scope.savePage4 = function () {
            $scope.template.pages.page = vm.pageData;
            generateJsonFile();
        }
        /*******************************************文字处理*************************************************************************/
        $scope.colorList = ['#ff001e', '#ff5c00', '#ff9700', '#61734d', '#838c97', '#8d4ac8', '#b8e986', '#7e6565', '#ffffff', '#f1e7d9', '#b5ce99', '#82c281', '#dac2ef', '#000', '#797979', '#7c330a', '#eec78d', '#444444', '#589ceb', '#4a7bc8', '#ddabab'];
        $scope.selectedColor = $scope.colorList[0];

        vm.textboxSelect = false;
        vm.textStyleTab = [
            {name: '字体', id: 'textStyleTabFont'},
            {name: '大小', id: 'textStyleTabSize'},
            {name: '样式', id: 'textStyleTabType'},
            {name: '颜色', id: 'textStyleTabColor'},
        ];
        vm.textStyleItem = 'textStyleTabFont';
        //添加文字
        $scope.fontShow = function () {
            vm.page5 = true;
            vm.fontTab = 'fontColor';
            $scope.selectedColor = $scope.colorList[0];

            // var _level ={
            //     seq: vm.pageData[vm.pageIndex].levels.level.length+1,
            //     textbox: textbox
            // }
            var _size = Math.floor(vm.pageData[vm.pageIndex].trimbox.height/8);

            vm.fontData = {
                seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                textbox: {
                    type: 'add',
                    geometry: {
                        x: '0',
                        y: '0',
                        width: 100,
                        height: 20
                    },
                    rotation: {
                        angle: '0'
                    },
                    text: '',
                    style: {
                        font: "",
                        size: _size,
                        color: $scope.selectedColor,
                        bold: false,
                        italic: false,
                        lineheight: _size,
                        letterspacing: 0,
                        align: 'left',
                        writingmode:'horizontal-tb'
                    },
                    fontuuid: vm.fontList[0].uuid,
                    identifier: "",
                    editable: true,
                    movable: true,
                    lock: false,
                    version: '2.0',
                    transparency: 1,
                    useradded: true
                }
            }
            var _fontStyle = [];
            for (var j = 0; j < vm.fontList[0].fontStyles.length; j++) {
                _fontStyle.push(vm.fontList[0].fontStyles[j].style)
            }
            if (_fontStyle.indexOf('NORMAL') != '-1') {
                vm.fontData.textbox.style.bold = false;
                vm.fontData.textbox.style.italic = false;
            } else {
                if (_fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    vm.fontData.textbox.style.bold = true;
                    vm.fontData.textbox.style.italic = true;
                } else {
                    if (_fontStyle.indexOf('ITALIC') != '-1') {
                        vm.fontData.textbox.style.italic = true;
                        vm.fontData.textbox.style.bold = false;
                    }
                    if (_fontStyle.indexOf('BOLD') != '-1') {
                        vm.fontData.textbox.style.bold = true;
                        vm.fontData.textbox.style.italic = false;
                    }
                }
            }
            getFontCSS(FontManagement, vm.fontData.textbox, $scope);
            vm.text = '';
            $timeout(function () {
                $('textarea.three-font-body-input').focus()
            },100);
        };
        //切换字体
        $scope.updateTextFont = function (data) {
            if (data == undefined) {
                threeInfo("文字添加失败，请上传字体!");
                return
            }
            if (!data.enable) {
                threeInfo("当前字体不可用，请更换字体");
                return
            }
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            vm.fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == data.uuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold = false;
            text.style.italic = false;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = false;
                text.style.italic = false;
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                    text.style.bold = true;
                    text.style.italic = true;
                } else {
                    if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                        text.style.italic = true;
                    }
                    if (vm.fontStyle.indexOf('BOLD') != '-1') {
                        text.style.bold = true;
                    }
                }
            }
            text.style.font = data.family;
            text.fontuuid = data.uuid;
            text.identifier = $scope.domain + data.fontStyles[0].identifier;
            text.loading = true;
            FontSupport2(FontManagement, text, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    text.text = msg.passed;
                    if (msg.success && !msg.supported) {
                        setTimeout(function(){
                            $(".sweet-alert").addClass('whiteBg');
                        })
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>温馨提示</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>特殊字符在作品文件中不能正常印刷<br>请您及时修改，当前字体不支持字符<br><span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                            html: true,
                            confirmButtonText: "返回修改"
                        },function(){
                            setTimeout(function(){
                                $(".sweet-alert").removeClass('whiteBg');
                            },200)
                        });
                        text.loading = false;
                    } else {
                        getFontCSS(FontManagement, text, $scope);
                        $timeout(function () {
                            sizeHeight();
                            fontStyle(data.uuid);
                            thumbnailInit();
                        })
                    }
                }
            })
        };

        function fontStyle(fontuuid) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            vm.fontStyle = [];
            for (var i = 0; i < vm.fontList.length; i++) {
                if (vm.fontList[i].uuid == fontuuid) {
                    for (var j = 0; j < vm.fontList[i].fontStyles.length; j++) {
                        vm.fontStyle.push(vm.fontList[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold1 = false;
            text.style.italic1 = false;
            if (vm.fontStyle.indexOf('NORMAL') == '-1') {
                text.style.normal = false;
            } else {
                text.style.normal = true;
            }
            if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1') {
                text.style.bold1 = true;
                text.style.italic1 = true;
            } else {
                if (vm.fontStyle.indexOf('ITALIC') != '-1') {
                    text.style.italic1 = true;
                }
                if (vm.fontStyle.indexOf('BOLD') != '-1') {
                    text.style.bold1 = true;
                }
            }
        }

        window.densityInput = function (e) {
            $timeout(function () {
                var val = $('#rangeChange').val();
                if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.size = parseInt(val);
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.lineheight = parseInt(val);
                }
                $timeout(function () {
                    var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                    var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width = _w;
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
                    thumbnailInit();
                });
            })
        }
        window.textStyleTransparency = function (e) {
            var val = $('#textStyleTransparency').val();
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.transparency = parseInt(val) / 100;
                vm.transparency = parseInt(val);
            }
        }
        //斜体
        $scope.operationItalic = function () {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.italic = !text.style.italic;
                $timeout(function () {
                    sizeHeight();
                }, 200)
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('BOLD') != '-1') {
                    if (text.style.bold) {
                        text.style.italic = !text.style.italic;
                        $timeout(function () {
                            sizeHeight();
                        }, 200)
                    }
                }
            }
        }
        //粗体
        $scope.operationBold = function () {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            if (vm.fontStyle.indexOf('NORMAL') != '-1') {
                text.style.bold = !text.style.bold;
                $timeout(function () {
                    sizeHeight();
                }, 200)
            } else {
                if (vm.fontStyle.indexOf('BOLD_ITALIC') != '-1' || vm.fontStyle.indexOf('ITALIC') != '-1') {
                    if (text.style.italic) {
                        text.style.bold = !text.style.bold;
                        $timeout(function () {
                            sizeHeight();
                        }, 200)
                    }
                }
            }
        }
        $scope.operationBarDir = function (item) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            text.style.align = item;
            thumbnailInit();
        };
        $scope.fontColorCb = function (item) {
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            text.style.color = item;
            thumbnailInit();
        }
        $scope.textLayout = function(item){
            var text = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox;
            var text1 = angular.copy(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox);
            var _writingmode = angular.copy(text.style.writingmode);
            text.style.writingmode = item;
            $timeout(function () {
                if(writingmodeInit(_writingmode) && writingmodeInit(text.style.writingmode)){
                }else{
                    text.geometry.width = text1.geometry.height;
                    text.geometry.height = text1.geometry.width;
                }
                thumbnailInit();
            },200)
        };

        function getAllFont() {
            FontManagement.getAll2({family: vm.selectFont}, function (res) {
                vm.fontList = res;
            });
        }

        //判断是ios or Android
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

        var textFlag = true;
        var inputEle = document.getElementById('three-font-body-input');

        // 兼容安卓文字输入（首行空格不显示bug）
        $scope.changeText = function (e) {
            if (isAndroid == true && isiOS == false) {
                fn(e);
                var targ = e.currentTarget ? e.currentTarget : e.srcElement;
                vm.text = vm.fontData.textbox.text = targ.value;
            }
        }

        /**
         * 兼容iOS文字输入
         */
        inputEle.addEventListener('compositionstart', function () {
            textFlag = false;
        });
        inputEle.addEventListener('compositionend', function (event) {
            textFlag = true;
            // compositionend 事件发生在 input 之后，故此需要此处调用 input 中逻辑
            if (isAndroid == false && isiOS == true) {
                vm.text = event.target.value;
            }
        });
        inputEle.addEventListener('input', function (event) {
            // 忽略一切非直接输入，不做逻辑处理
            if (textFlag) {
                if (isAndroid == false && isiOS == true) {
                    vm.text = event.target.value;
                }
            };
        });

        $scope.savePage5 = function () {
            if (!vm.fontData.textbox.text) {
                vm.page4 = true;
                vm.page5 = false;
                return
            }
            if(vm.text){
                vm.fontData.textbox.text =  vm.text;
            }
            FontSupport2(FontManagement, vm.fontData.textbox, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    // vm.fontData.textbox.text = msg.passed;
                    if (msg.success && !msg.supported) {
                        setTimeout(function(){
                            $(".sweet-alert").addClass('whiteBg');
                        })
                        swal({
                            title: "<img src='content/images/icons/ic_tishi.svg'><div style='color:#666;font-size: 16px;margin-top: 16px;'>温馨提示</div>",
                            text: "<div style='font-size: 12px;line-height: 24px;color: #333;'>特殊字符在作品文件中不能正常印刷<br>请您及时修改，当前字体不支持字符<br><span style='color:#FFA91B;font-size: 14px'>" + msg.failed + "</span></div>",
                            html: true,
                            confirmButtonText: "返回修改"
                        },function(){
                            setTimeout(function(){
                                $(".sweet-alert").removeClass('whiteBg');
                            },200)
                        });
                    } else {
                        vm.page4 = true;
                        vm.page5 = false;
                        vm.fontData.textbox.style.color = $scope.selectedColor;
                        // vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                        // vm.fontData.textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                        // vm.fontData.textbox.geometry.width = Number(($("#c-xml-page-material-fontId").width() * ( vm.windowDpi / 72) + Number(10)) / vm.pageData[vm.pageIndex].custom.ratio1 * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1));
                        // vm.fontData.textbox.geometry.height = Number(($("#c-xml-page-material-fontId").height() * ( vm.windowDpi / 72) + Number(5)) / vm.pageData[vm.pageIndex].custom.ratio1) * (1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1 > 1 ? 1 : 1 / 12 * vm.fontData.textbox.style.size * vm.pageData[vm.pageIndex].custom.ratio1);
                        if (vm.fontData.textbox.type == 'add') {
                            vm.fontData.textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                            vm.fontData.textbox.geometry.x = (vm.pageData[vm.pageIndex].trimbox.width - vm.fontData.textbox.geometry.width) / 2 + vm.pageData[vm.pageIndex].trimbox.x;
                            vm.fontData.textbox.geometry.y = (vm.pageData[vm.pageIndex].trimbox.height - vm.fontData.textbox.geometry.height) / 2 + vm.pageData[vm.pageIndex].trimbox.y;
                            vm.pageData[vm.pageIndex].levels.level.push(vm.fontData)
                            vm.levelIndex = vm.pageData[vm.pageIndex].levels.level.length-1;
                        }
                        if (vm.fontData.textbox.type == 'edit') {
                            // if (vm.fontData.textbox.geometry.width >= vm.pageData[vm.pageIndex].trimbox.width) {
                            //     // vm.fontData.textbox.geometry.width = vm.pageData[vm.pageIndex].trimbox.width;
                            //     vm.fontData.textbox.geometry.x = vm.pageData[vm.pageIndex].trimbox.x;
                            // }
                            // if (vm.fontData.textbox.geometry.height >= vm.pageData[vm.pageIndex].trimbox.height) {
                            //     // vm.fontData.textbox.geometry.height = vm.pageData[vm.pageIndex].trimbox.height
                            //     vm.fontData.textbox.geometry.y = vm.pageData[vm.pageIndex].trimbox.y;
                            // }
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] = vm.fontData;
                        }
                        sizeHeight1(function () {
                            thumbnailInit();
                        })
                    }
                }
            })
        };
        //删除文字
        $scope.deleteTextbox = function (e) {
            fn(e)
            if (vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox) {
                vm.pageData[vm.pageIndex].levels.level.splice(vm.levelIndex, 1);
                vm.levelIndex = null;
                vm.levelType = null;
                $scope.makeBody();
                thumbnailInit();
            }
        }
        $scope.editTextbox = function (e, index, item) {
            fn(e)
            vm.page5 = true;
            vm.fontData = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex];
            vm.fontData.textbox.type = 'edit';
            $scope.selectedColor = vm.fontData.textbox.style.color;
            vm.fontTab = 'fontColor';
            vm.text = vm.fontData.textbox.text;
            $timeout(function () {
                $('textarea.three-font-body-input').focus()
            },100);
        }
        //文字样式
        $scope.textStyleTextbox = function () {
            vm.textStyleShow = true;
        }

        function sizeHeight() {
            sizeHeight1();
            // $timeout(function () {
            //     var _h = Number($('.pitch1').find('.c-xml-page-textboxe-text span').height()) + 5;
            //     vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
            //     $scope.$digest();
            // })
        }
        vm.sizeHeight1 = sizeHeight1;
        function sizeHeight1(callback) {
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                if(!writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.height = _h;
                }
                if(writingmodeInit(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.style.writingmode)){
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].textbox.geometry.width = _w;
                }
                if(callback){callback()}
                $scope.$digest();
            })
        }
        //字体样式
        $scope.textStyleTabCb = function (item) {
            vm.textStyleItem = item.id;
        }
        $scope.textStyleHide = function () {
            vm.textStyleShow = false;
        }

        $scope.writingmodeInit = function (item) {
            if(item == 'vertical-lr' || item == 'vertical-rl'){
                return true
            }else{
                return false
            }
        }
        /*******************************************照片框处理*************************************************************************/
        vm.isImageboxSelectItem = false;
        //展开
        vm.isModeOut = false;
        $scope.modeIn = function () {
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if (_imagebox.bokehtype != 'None') {
                if (_imagebox.image && _imagebox.image.resource.identifier) {
                    imageboxModeIn(_imagebox, function (imagebox) {
                        vm.isModeOut = true;
                        thumbnailInit();
                    })
                }
            }
        };
        //缩放
        $scope.modeOut = function () {
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if (_imagebox.bokehtype == 'None') {
                if (_imagebox.image && _imagebox.image.resource.identifier) {
                    imageboxModeOut(_imagebox, function (imagebox) {
                        vm.isModeOut = false;
                        thumbnailInit();
                    })
                }
            }
        };


        $scope.cropImageboxMassive = function () {
            imageboxMassive(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, vm.pageData[vm.pageIndex], function () {
                thumbnailInit();
            });
        }
        $scope.cropImageboxMin = function () {
            imageboxMin(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, vm.pageData[vm.pageIndex], function () {
                thumbnailInit();
            });
        }
        $scope.cropImageboxRotate = function (flag) {
            imageboxRotate($http,$scope, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, flag, function () {
                thumbnailInit();
            });
        }
        $scope.cropImagRestore = function () {
            imagRestore($http,$scope, vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox, function () {
                thumbnailInit();
            });
        }
        $scope.overturnImagebox = function () {
            imageboxOverturn(vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox);
            thumbnailInit();
        }

        vm.mattingShow = false;
        vm.filterShow = false;
        vm.filterType='';
        vm.filterList=[
            {img:'content/images/filter1.png',type:''},
            {img:'content/images/filter2.png',type:'selfie_anime'},
            {img:'content/images/filter3.png',type:'wave'},
            {img:'content/images/filter4.png',type:'warm'},
            {img:'content/images/filter5.png',type:'scream'},
            {img:'content/images/filter6.png',type:'gothic'},
            {img:'content/images/filter7.png',type:'lavender'},
        ];
        vm.mattingList=[
            {img:'content/images/filter8.png',type:''},
            {img:'content/images/filter9.png',type:'body_seg'},
        ];
        $scope.filterCb = function(item){
            if(!vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox || !vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image || !vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier){
                return;
            }
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if(item.type == _imagebox.filterType){
                return;
            }
            vm.threeLoading = true;
            vm.threeLoadingText = '加载中...';
            if(_imagebox.image.resourceid){
                Gallery.getOne({id:_imagebox.image.resourceid},function (res) {
                    if(res.imgSize > 8 * 1024 * 1024){
                        threeInfo("图片不能大于8M");
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        return
                    }
                    var _identifier = $scope.domain + res.identifier;
                    if(!item.type){
                        _imagebox.filterType=item.type;
                        vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = _identifier;
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        thumbnailInit();
                    }else {
                        Convert.imageEnhance({
                            imageUrl: _identifier,
                            type: item.type
                        }, function (resp) {
                            if(resp.status == 200){
                                vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = $scope.domain + resp.message;
                                _imagebox.filterType=item.type;
                                thumbnailInit();
                            }else{
                                threeInfo(resp.message)
                            }
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                        }, function () {
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            threeInfo("加载失败")
                        });
                    }
                }, function () {
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    threeInfo("加载失败")
                })
            }else {
                if(!_imagebox.image.resource.identifier1){
                    _imagebox.image.resource.identifier1 = angular.copy(_imagebox.image.resource.identifier);
                }
                if(!item.type){
                    _imagebox.filterType=item.type;
                    vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = _imagebox.image.resource.identifier1;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    thumbnailInit();
                }else {
                    Convert.imageEnhance({
                        imageUrl: _imagebox.image.resource.identifier1,
                        type: item.type
                    }, function (resp) {
                        if(resp.status == 200){
                            vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.resource.identifier = $scope.domain + resp.message;
                            _imagebox.filterType=item.type;
                            thumbnailInit();
                        }else{
                            threeInfo(resp.message)
                        }
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                    }, function () {
                        vm.threeLoading = false;
                        vm.threeLoadingText = '';
                        threeInfo("加载失败")
                    });
                }
            }
        };
        function threeInfo(item){
            vm.threeError = true;
            vm.threeErrorText=item;
            $timeout(function () {
                vm.threeError = false;
                vm.threeErrorText="";
            },1500)
        }
        $scope.mattingImage = function () {
            vm.mattingShow = true;
        };
        $scope.cutImage = function () {
            $timeout(function () {
                vm.filterShow = true;
            })
        };
        $scope.filterChoose = function (item) {
            switch (item) {
                case 'selfie_anime':
                    return "人像动漫化";
                    break;
                case 'warm':
                    return "浪漫巴黎";
                    break;
                case 'wave':
                    return "金阳破浪";
                    break;
                case 'lavender':
                    return "紫藤奇缘";
                    break;
                case 'scream':
                    return "挪威森林";
                    break;
                case 'gothic':
                    return "情迷罗马";
                    break;
                case 'body_seg':
                    return "人像抠图";
                    break;
                default:
                    return "原画";
            }
        }

        var dragGal = {};
        var drag = false;
        var startChangeTimeout;
        vm.isSmallPic = false;
        window.startChange = function (e) {
            if(vm.mapDepot){
                startChangeTimeout = setTimeout(function() {
                    vm.isSmallPic = true;
                    $scope.$digest();
                }, 200);
                drag = true;
                var i = e.target.dataset.imgindex;
                $scope.galleryArr = vm.photoAll[i];
                $scope.galleryChoose = vm.photoAll[i].identifier;

                $scope.galleryId = i;
                $scope.galleryX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX - 53 : 0;
                $scope.galleryY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY - 40 : 0;
                dragGal.clientX = e.changedTouches[0].clientX ? e.changedTouches[0].clientX : 0;
                dragGal.clientY = e.changedTouches[0].clientY ? e.changedTouches[0].clientY : 0;
                dragGal.pageX = e.changedTouches[0].pageX ? e.changedTouches[0].pageX : 0;
                dragGal.pageY = e.changedTouches[0].pageY ? e.changedTouches[0].pageY : 0;
                dragGal.coordX = $scope.galleryX;
                dragGal.coordY = $scope.galleryY;
                $scope.$digest();
                return false;
            }
        };

        window.dragChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (drag) {
                //手指离开屏幕
                $scope.galleryX = (dragGal.coordX + (e.changedTouches[0].clientX - dragGal.clientX));
                $scope.galleryY = (dragGal.coordY + (e.changedTouches[0].clientY - dragGal.clientY));

                // if (Math.abs($scope.galleryY - dragGal.coordY) > 10) {
                //     vm.isSmallPic = true;
                // }

                //向上滑动;
                if ($scope.galleryY <= 0) {
                    $scope.galleryY = 0;
                }
                //向左滑动;        <-----
                if ($scope.galleryX <= 0) {
                    $scope.galleryX = 0;
                }
                //向下滑动;
                if ($scope.galleryY >= $scope.dHeight - 62) {
                    $scope.galleryY = $scope.dHeight - 62;
                }
                // 向右滑动;    ---->
                if ($scope.galleryX >= $scope.dWidth - 62) {
                    $scope.galleryX = $scope.dWidth - 62;
                }

                var _picTop = $("#small-pic").offset().top;
                var _picLeft = $("#small-pic").offset().left;
                var _exist = false;
                for (var i1 = vm.pageData[vm.pageIndex].levels.level.length-1; i1 >= 0; i1--) {
                    var _level = vm.pageData[vm.pageIndex].levels.level[i1];
                    if (_level.imagebox) {
                        var _imageboxLeft = $(".imagebox" + i1).offset().left;
                        var _imageboxTop = $(".imagebox" + i1).offset().top;
                        var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                        var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                        if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight) && !_exist) {
                            _exist = true;
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(103, 106, 108, 0.8)")
                        }else{
                            $(".imagebox" + i1).find(".imageboxHint").css("background", "rgba(0,0,0,0.2)")
                        }
                    }
                }
                $scope.$digest();
                return false;
            }
        };

        window.stopChange = function (e) {
            clearTimeout(startChangeTimeout);
            if (!drag) {
                return
            }
            drag = false;
            var _picTop = $("#small-pic").offset().top;
            var _picLeft = $("#small-pic").offset().left;
            for (var i1 = vm.pageData[vm.pageIndex].levels.level.length-1; i1 >= 0; i1--) {
                var _level = vm.pageData[vm.pageIndex].levels.level[i1];
                if (_level.imagebox) {
                    var _imageboxLeft = $(".imagebox" + i1).offset().left;
                    var _imageboxTop = $(".imagebox" + i1).offset().top;
                    var _imageboxRight = _imageboxLeft + $(".imagebox" + i1).width();
                    var _imageboxBottom = _imageboxTop + $(".imagebox" + i1).height();
                    if ((_imageboxTop < _picTop) && (_picTop < _imageboxBottom) && (_imageboxLeft < _picLeft) && (_picLeft < _imageboxRight)) {
                        $scope.replaceImagebox1($scope.galleryArr, i1)
                        break;
                    }
                }
            }
            vm.isSmallPic = false;
            $scope.$digest();
        };


        $scope.addImageboxImg = function (item) {
            if(vm.isAddImagebox){
                imageboxAddFn(item)
            }else{
                if(vm.pageData[vm.pageIndex] && vm.pageData[vm.pageIndex].levels.level && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex] && vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox){
                    replaceImagebox(item)
                }else{
                    for (var i = 0; i < vm.pageData[vm.pageIndex].levels.level.length; i++) {
                        var _level = vm.pageData[vm.pageIndex].levels.level[i];
                        if (_level.imagebox && _level.imagebox.image && !_level.imagebox.image.resource.identifier) {
                            vm.levelIndex = i;
                            replaceImagebox(item, function () {
                                vm.levelIndex = null;
                            })
                            break;
                        }
                    }
                }
            }
        };
        $scope.replaceImagebox1 = function (data, index) {
            vm.levelIndex = index;
            replaceImagebox(data)
        }

        function replaceImagebox(data,callback) {
            var item = angular.copy(data);
            var _imagebox = vm.pageData[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            var identifier = _imagebox.mask.resource.identifier;
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                resourceid:item.id,
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                imageInfo:item.imageInfo,
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0
            }
            addImagebox($http, _imagebox, function () {
                imageSelectInit();
                thumbnailInit();
                if(callback){callback()}
            });
        }

        function imageSelectInit() {
            vm.photoSelectAll = [];
            for (var i = 0; i < vm.pageData.length; i++) {
                for (var j = 0; j < vm.pageData[i].levels.level.length; j++) {
                    var _level = vm.pageData[i].levels.level[j];
                    if (_level.imagebox) {
                        for (var k = 0; k < vm.photoAll.length; k++) {
                            if (_level.imagebox.image.resourceId == vm.photoAll[k].id) {
                                vm.photoSelectAll.push(vm.photoAll[k]);
                                break
                            }
                        }
                    }
                }
            }
        }


        //保存
        $scope.isSave = false;
        vm.isDocumentTextDetection = false;
        function articleOverdue(callback){
            if(vm.article.id) {
                Article.get({id: vm.article.id}, function (article) {
                    if(article.fileName != vm.article.fileName){
                        MessageService.minConfirm({
                            title:"提示",
                            cancel:"取消",
                            confirm:"刷新",
                            msg:"保存失败，当前作品不是最新版本，请刷新后再操作。"
                        },function(){
                            location.reload();
                        })
                    }else{
                        callback()
                    }
                })
                return
            }
            callback()
        }
        $scope.save = function () {
            articleOverdue(function () {
                if ($scope.isSave) {
                    return;
                }
                var _documentTextDetection = documentTextDetection1($scope.template.pages.page);
                if(!_documentTextDetection.pass){
                    vm.isDocumentTextDetection = true;
                    vm.documentTextDetection = _documentTextDetection.pages;
                    return;
                }
                if ($scope.template.pages.page.length < $scope.PAGELEN) {
                    SweetAlert.swal({
                        title: '模板加载中，请稍候...',
                        imageUrl: Theme.sweetHintImg(),
                        text: "",
                        timer: 2000,
                        showConfirmButton: false
                    });
                    return
                }

                if (vm.article.ordered) {
                    SweetAlert.swal({
                        title: '温馨提示',
                        imageUrl: Theme.sweetHintImg(),
                        text: "该作品已有对应订单，如需修改，则会保存为一个新的作品。",
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                //判断是否有图片未上传  且是否继续保存
                if (!$scope.isContinue()) {
                    // vm.completed = false;
                    setTimeout(function(){
                        $(".sweet-alert").addClass('whiteBg');
                    })
                    SweetAlert.swal({
                        title: '当前作品没有制作完成，确认保存？',
                        // imageUrl: Theme.sweetHintImg(),
                        showCancelButton: true,
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function (isConfirm) {
                        setTimeout(function(){
                            $(".sweet-alert").removeClass('whiteBg');
                        },200)

                        if (isConfirm) {
                            $timeout(function () {
                                generateJsonFile();
                            }, 500);
                            return;
                        }
                    })
                } else {
                    // vm.completed = true;
                    generateJsonFile();
                }
            })
        }
        vm.isPageWarnExamine = false;
        function generateJsonFile() {
            vm.threeLoading = true;
            vm.threeLoadingText = '加载中...';

            vm.deleteList = 0;
            var gallieryIds = [];
            for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                var page = $scope.template.pages.page[i];
                if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                    continue;
                }
                for (var j = page.levels.level.length - 1; j >= 0; j--) {
                    var level = page.levels.level[j];
                    if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                        continue;
                    }
                    gallieryIds.push(level.imagebox.image.resourceid);
                }
            }
            if (gallieryIds.length>0) {
                Gallery.checkDeleted(gallieryIds, function(list){
                    // vm.deleteList = [{id:gallieryIds[0]}];
                    // vm.completed = false;
                    // vm.isDestroyed = true;

                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    if (list == null|| list.length ==0) {
                        generateJsonFile1();
                    }else{
                        vm.deleteList = list;
                        vm.completed = false;
                        vm.isDestroyed = true;
                        return;
                    }
                }, function(){
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    MessageService.error("检测图片失败，请稍后再试。");
                })
            }else{
                generateJsonFile1();
            }
        }

        $scope.galleryDestroyConfirm = function(){
            $scope.removeGallery();
            generateJsonFile1();
        };


        function generateJsonFile1(){
            if(!vm.isPageWarnExamine && pageWarnExamine(vm.pageData, vm.imgSize, vm.imgDpi)){
                vm.isPageWarnExamine = true;
                vm.threeLoading = false;
                vm.threeLoadingText = '';
                return
            }
            vm.isPageWarnExamine = false;
            $scope.isSave = true;
            vm.threeLoading = true;
            vm.threeLoadingText = '加载中...';
            thumbnailInit(function () {
                vm.article.document = saveData();
                vm.article.document.hostinfo = hostInfo();
                thumbnailInit1(function (){
                    if (vm.isEdit) {
                        vm.article.completed = vm.completed;
                        vm.article.unFilledCount = vm.unFilledCount;
                        Article.update(vm.article, function (resp) {
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            $scope.isSave = false;
                            swal({
                                title: "修改成功",
                                text: "作品已同步到我的作品",
                                imageUrl: Theme.sweetSuccedImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            if(vm.store.orderWay == 'editorThenOrder' || !vm.store.orderWay){
                                $state.go('product.view5',{storeId: vm.storeId, aid: resp.id, soid: vm.salesOrderId, plid: vm.productLineId});
                            }
                            if(vm.store.orderWay == 'orderThenEditor'){
                                works(resp)
                            }
                        }, function () {
                            $scope.isSave = false;
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            swal({
                                title: "保存失败",
                                text: "保存出错了，请稍后再试，或者联系工作人员",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        })
                    } else {
                        //格式化数据
                        var data = {
                            id: vm.article.id,
                            name: vm.article.name,
                            completed: vm.completed,
                            sku: vm.article.sku,
                            skuNumber: vm.article.skuNumber,
                            cover: vm.article.cover,
                            document: vm.article.document,
                            productType: 'CustomPrint',
                            fileName: vm.article.fileName,
                            productId: vm.article.productId,
                            productName: vm.article.productName,
                            categoryId: vm.article.categoryId,
                            categoryName: vm.article.categoryName,
                            categoryStoreId: vm.article.categoryStoreId,
                            categoryStoreName: vm.article.categoryStoreName,
                            folderId: vm.article.folderId,
                            itemValueId: vm.article.itemValueId,
                            themeClassifyId: vm.article.themeClassifyId,
                            themeClassifyName: vm.article.themeClassifyName,
                            productXmlId: vm.article.productXmlId,
                            productXmlName: vm.article.productXmlName,
                            musicIdentify: vm.article.musicIdentify,
                            editorType: vm.article.editorType,
                            unFilledCount: vm.unFilledCount,
                            modelBlendentUuid: vm.article.modelBlendentUuid,
                        }
                        Article.save(data, function (resp) {
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            swal({
                                title: "保存成功",
                                text: "作品已同步到我的作品",
                                imageUrl: Theme.sweetSuccedImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                            $scope.isSave = false;
                            if(vm.store.orderWay == 'editorThenOrder' || !vm.store.orderWay){
                                $state.go('product.view5',{storeId: vm.storeId, aid: resp.id, soid: vm.salesOrderId, plid: vm.productLineId});
                            }
                            if(vm.store.orderWay == 'orderThenEditor'){
                                works(resp)
                            }
                        }, function (resp) {
                            vm.threeLoading = false;
                            vm.threeLoadingText = '';
                            $scope.isSave = false;
                            swal({
                                title: "保存失败",
                                text: "保存出错了，请稍后再试，或者联系工作人员",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 3000,
                                showConfirmButton: false
                            });

                        });
                    }
                    localStorage.removeItem("photoSelectAll");
                })
            })
        }

        function works(article){
            if (vm.isEdit) {
                SalesOrder.updateByArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id
                }, function (res) {
                    $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                }, function (res) {
                    $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                })
            }else{
                SalesOrder.updateArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id,
                    copy: 1,
                }, function (res) {
                    $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                }, function (res) {
                    $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                })
            }
        }

        $scope.isContinue = function () {
            var hasEmpty = true;
            vm.unFilledCount = 0;
            for (var i = 0; i < $scope.template.pages.page.length; i++) {
                var _page = $scope.template.pages.page[i];
                if (_page.levels.level) {
                    for (var j = 0; j < _page.levels.level.length; j++) {
                        var _level = _page.levels.level[j];
                        if (_level.imagebox) {
                            if (!_level.imagebox.image || !_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                hasEmpty = false;
                                vm.unFilledCount++;
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty;
            return hasEmpty;
        };

        function saveData() {
            return documentMM($scope.template, $scope.domain);
        }

        function fn($event) {
            var $event = $event || window.event;
            if ($event && $event.preventDefault) {
                $event.preventDefault();
            } else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }

        $scope.threeModalHide = function () {
            vm.threeIsModal = false;
            vm.threeModalText = "";
            vm.threeIsModal1 = false;
            vm.threeModalText1 = "";
        }
        $scope.threeModalSave = function () {
            vm.threeIsModal = false;
            vm.threeModalText = "";
            vm.threeIsModal1 = false;
            vm.threeModalText1 = "";
            templateReplace();
        };
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreConfig.getAllByTypes({storeId: vm.storeId,type: ["IMGSIZE", "IMGDPI"]}, function (res) {
            if(res.status == 200){
                res.message.forEach(function (item) {
                    if(item.type === 'IMGSIZE'){
                        vm.imgSize = item.value;
                    }
                    if(item.type === 'IMGDPI'){
                        vm.imgDpi = item.value;
                    }
                })
            }
        });
        //生成缩略图
        vm.isHtml2canvas = false;
        vm.isThreeView3d1 = false;
        vm.isThreeView3d = true;
        vm.threeView3dW=110;
        vm.threeView3dH=110;
        vm.pageData1 = [];
        vm.threeView3d={
            top: 100,
            right: 5
        }

        $scope.threeView3d1Cb = function () {
            vm.isThreeView3d1=true;
            // vm.threeView3dW=$(window).width();
            // vm.threeView3dH=400;
        }
        $scope.threeView3d1CbHide = function () {
            vm.isThreeView3d1=false;
            // vm.threeView3dW=110;
            // vm.threeView3dH=110;
        }
        var a = null;
        vm.thumbnailInit = thumbnailInit;
        function thumbnailInit(callback) {
            if(vm.productXmlData.modelType === 'THREE'){
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function() {
                    $timeout(function () {
                        html2canvas_adapt(document.getElementById('thumbnail-view'), {
                            onrendered: function (canvas) {
                                if(!canvas){
                                    if (callback) {callback()};
                                    return
                                }
                                var url = canvas.toDataURL("image/png");
                                var blob = dataURLtoBlob(url);
                                var formData = new FormData();
                                formData.append('file', blob);
                                formData.append('key', '3dThumbnail-' + UidService.get());
                                formData.append('token', vm.token);
                                $.ajax({
                                    cache: true,
                                    type: "POST",
                                    url: 'https://upload.qiniup.com/',
                                    data: formData,
                                    async: false,
                                    contentType: false,
                                    processData: false,
                                    success: function (data) {
                                        vm.pageData[vm.pageIndex].pagecover = {
                                            identifier: $scope.domain + data.key,
                                            provider: 'qiniu'
                                        }
                                        vm.isHtml2canvas = true;
                                        vm.pageData1 = angular.copy(vm.pageData);
                                        if(callback){callback()}
                                        $scope.$apply();
                                    }
                                })
                            },
                            error:function(){
                                if (callback) {callback()};
                            },
                            letterRendering: true,
                            useCORS: true,
                            width: vm.pageData[vm.pageIndex].trimbox.width*vm.pageData[vm.pageIndex].custom.thumbnailScale,
                            height: vm.pageData[vm.pageIndex].trimbox.height*vm.pageData[vm.pageIndex].custom.thumbnailScale,
                            windowWidth: document.body.scrollWidth,
                            windowHeight: document.body.scrollHeight,
                            x: 0,
                            y: 0,
                            backgroundColor: "rgba(0, 0, 0, 0)"
                        });
                    })
                },3000)
            }else{
                if(callback){callback()}
            }
        }
        //生成封面图
        function thumbnailInit1(callback) {
            vm.isCover = false;
            $timeout(function () {
                if(!vm.isCover){
                    vm.isCover = true;
                    vm.article.covered=false;
                    vm.article.cover=null;
                    callback();
                }
            },5000)
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view1'), {
                    onrendered: function (canvas) {
                        if(!vm.isCover){
                            vm.isCover = true;
                            if(!canvas){
                                vm.article.covered=false;
                                vm.article.cover=null;
                                callback();
                                return
                            }
                            var url = canvas.toDataURL("image/png");
                            var blob = dataURLtoBlob(url);
                            var formData = new FormData();
                            formData.append('file', blob);
                            formData.append('key', '3dThumbnail-' + UidService.get());
                            formData.append('token', vm.token);
                            $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: false,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    var identifier = data.key;
                                    vm.article.covered=true;
                                    vm.article.cover=identifier;
                                    if(callback){callback()};
                                }
                            })
                        }
                    },
                    error:function(){
                        vm.article.covered=false;
                        vm.article.cover=null;
                        if (callback) {callback()};
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: vm.pageData[0].custom.width*vm.pageData[0].custom.thumbnailScale,
                    height: vm.pageData[0].custom.height*vm.pageData[0].custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            });
        }

        window.threeView3dStart = function (e) {
            vm.threeView3d1 = angular.copy(vm.threeView3d);
            vm.threeView3d1.x = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;          //鼠标到页面左边的距离
            vm.threeView3d1.y = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;           //鼠标到页面顶部的距离
            $scope.$digest();
        };
        window.threeView3dOn = function (e) {
            $timeout(function () {
                var nx = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;
                var ny = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - vm.threeView3d1.x;
                var nt = ny - vm.threeView3d1.y;
                vm.threeView3d.right = vm.threeView3d1.right - nl;
                vm.threeView3d.top = vm.threeView3d1.top + nt;
            })
        }

        //涂鸦
        vm.graffitiColors = ["#000000", "#E0E0E0", "#FFFFFF", "#FFF0F6", "#FFD6E7", "#EFDBFF", "#FF85C0", "#FF7875", "#D46B08", "#FFC069", "#FADB14", "#FFF566", "#D9F7BE", "#95DE64", "#87E8DE", "#096DD9", "#BAE7FF", "#B37FEB", "#722ED1"];
        vm.graffitiColor = '#000000';
        vm.graffitiPage = 1;

        window.graffitiPenChen = function (e) {
            var val = $('#graffitiPen').val();
            vm.graffitiPen = parseInt(val);
        }

        $scope.graffitiColorSet = function(item){
            vm.graffitiColor = item;
        }
        $scope.graffitiHide = function () {
            vm.isGraffiti = false;
            if (!vm.oldGraffitiMagnify) {
                $scope.editboxMagnify();
            }
        }
        $scope.graffitiSave = function () {
            vm.isGraffiti = false;
            if(vm.step>-1){
                var imgScale = 2;
                var canvas = document.getElementById('graffitiCanvas');
                var url = canvas.toDataURL('image/png');
                clearImageEdgeBlank(url, 0, function (img, x, y) {
                    var blob = dataURLtoBlob(img);
                    var formData = new FormData();
                    formData.append('file', blob);
                    formData.append('key', '3dThumbnail-' + UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            var imageart = {
                                resource:{
                                    identifier: $scope.domain + data.key
                                }
                            }
                            imageartAdd($http, imageart, vm.pageData[vm.pageIndex].trimbox, function(imageart){
                                imageart.geometry.x = x / imgScale;
                                imageart.geometry.y = y / imgScale;
                                var _level = {
                                    seq: vm.pageData[vm.pageIndex].levels.level.length + 1,
                                    imageart: imageart
                                }
                                vm.pageData[vm.pageIndex].levels.level.push(_level);
                                thumbnailInit();
                            }, imgScale, true)
                        }
                    })
                })
            }
            if (!vm.oldGraffitiMagnify) {
                $scope.editboxMagnify();
            }
        }
        function clearImageEdgeBlank(url, padding, callback) {
            // create canvas
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");

            // create image
            var image = new Image();
            image.onload = draw;
            image.src = url;
            image.crossOrigin = "Anonymous";

            function draw() {
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                var data = imageData.data;
                var width = imageData.width;
                var height = imageData.height;
                // var width = imageData;
                // var height = imageData;
                // 裁剪需要的起点和终点,初始值为画布左上和右下点互换设置成极限值。
                var startX = width,
                    startY = height,
                    endX = 0,
                    endY = 0;
                /*
                col为列，row为行，两层循环构造每一个网格，
                便利所有网格的像素，如果有色彩则设置裁剪的起点和终点
                */
                for (var col = 0; col < width; col++) {
                    for (var row = 0; row < height; row++) {
                        // 网格索引
                        var pxStartIndex = (row * width + col) * 4;

                        // 网格的实际像素RGBA
                        var pxData = {
                            r: data[pxStartIndex],
                            g: data[pxStartIndex + 1],
                            b: data[pxStartIndex + 2],
                            a: data[pxStartIndex + 3]
                        };

                        // 存在色彩：不透明
                        var colorExist = pxData.a !== 0;

                        /*
                        如果当前像素点有色彩
                        startX坐标取当前col和startX的最小值
                        endX坐标取当前col和endX的最大值
                        startY坐标取当前row和startY的最小值
                        endY坐标取当前row和endY的最大值
                        */
                        if (colorExist) {
                            startX = Math.min(col, startX);
                            endX = Math.max(col, startX);
                            startY = Math.min(row, startY);
                            endY = Math.max(row, endY);
                        }
                    }
                }

                // 右下坐标需要扩展1px,才能完整的截取到图像
                endX += 1;
                endY += 1;

                // 加上padding
                startX -= padding;
                startY -= padding;
                endX += padding;
                endY += padding;

                // 根据计算的起点终点进行裁剪
                var cropCanvas = document.createElement("canvas");
                var cropCtx = cropCanvas.getContext("2d");
                cropCanvas.width = endX - startX;
                cropCanvas.height = endY - startY;
                cropCtx.drawImage(
                    image,
                    startX,
                    startY,
                    cropCanvas.width,
                    cropCanvas.height,
                    0,
                    0,
                    cropCanvas.width,
                    cropCanvas.height
                );

                // rosolve裁剪后的图像字符串
                callback(cropCanvas.toDataURL(), startX, startY);
            }
        }
        $scope.graffitiRetreat = function () {
            if (vm.step >= 0) {
                $timeout(function () {
                    var canvas = document.getElementById('graffitiCanvas');
                    vm.step--;
                    vm.canvasHistory = vm.canvasHistory.slice(0,-1);
                    vm.context.clearRect(0, 0, canvas.width, canvas.height);
                    if(vm.canvasHistory[vm.step]){
                        vm.context.putImageData(vm.canvasHistory[vm.step], 0, 0)
                    }
                })
            }
        }

        vm.canvasHistory = [];
        vm.canvasOffsetX = 0;
        vm.canvasOffsetY = 0;
        function canvasInit() {
            $timeout(function () {
                var imgScale = 2;
                var cvsWidth = vm.pageData[vm.pageIndex].mediabox.width * imgScale;
                var cvsHeight = vm.pageData[vm.pageIndex].mediabox.height * imgScale;
                var cvsRatio = vm.pageData[vm.pageIndex].custom.ratio1;
                var offsetX = -20;
                var offsetY = -180;

                var canvas = document.getElementById('graffitiCanvas');
                canvas.width = cvsWidth;
                canvas.height = cvsHeight;
                vm.context = canvas.getContext('2d');
                vm.context.translate(offsetX, offsetY);
                vm.context.scale(imgScale / cvsRatio, imgScale / cvsRatio);


                vm.graffitiPen = 3;
                vm.canvasHistory = [];
                vm.step = -1;
                vm.eraserEnabled = false;
                listenToMouse(canvas);
            })
        }
        function drawLine(x1, y1, x2, y2) {
            vm.context.beginPath();
            vm.context.strokeStyle = vm.graffitiColor;
            vm.context.lineCap="round";
            vm.context.moveTo(x1, y1) // 起点
            vm.context.lineWidth = vm.graffitiPen;
            vm.context.lineTo(x2, y2) // 终点
            vm.context.stroke();
            vm.context.closePath()
        }
        function listenToMouse(canvas) {
            var usingdata = false
            var lastPoint = {
                x: undefined,
                y: undefined
            };
            // 特性检测
            canvas.addEventListener("touchstart",function(aaa) {
                $timeout(function () {
                    vm.graffitiPage = 1;
                    var x = aaa.targetTouches[0].clientX;
                    var y = aaa.targetTouches[0].clientY;
                    usingdata = true
                    if (vm.eraserEnabled) {
                        vm.context.clearRect(x - 5, y - 5, 10, 10)
                    } else {
                        lastPoint = {
                            "x": x,
                            "y": y
                        }
                    }
                })
            })
            canvas.addEventListener("touchmove",function(aaa){
                var x = aaa.changedTouches[0].clientX;
                var y = aaa.changedTouches[0].clientY;
                if (!usingdata) {
                    return
                }
                if (vm.eraserEnabled) {
                    vm.context.clearRect(x - 5, y - 5, 10, 10)
                } else {
                    var newPoint = {
                        "x": x,
                        "y": y
                    }
                    drawLine(lastPoint.x, lastPoint.y, newPoint.x, newPoint.y)
                    lastPoint = newPoint
                }
            })
            canvas.addEventListener("touchend",function(event){
                usingdata = false
                vm.step++;
                vm.canvasHistory.push(vm.context.getImageData(0, 0, canvas.width, canvas.height))
            })
        }
        //取色器
        $scope.graffitiPageCb = function (item) {
            vm.graffitiPage = 2;
            $timeout(function () {
                var width = document.body.clientWidth;
                var height = 200;
                var can = document.getElementById('ColorTip');
                var ctx = can.getContext('2d');
                var cur = document.getElementById('cur');
                var curColor = 'rgba(255,0,0,1)';
                can.width = width;
                can.height = 200;
                colorBar();
                colorBox(curColor);
                bind();
                /**
                 *  绘制左边颜色条
                 *  用createLinearGradient命令创建一个gradientBar对象 然后给gradientBar对象填充颜色就行了
                 *  这里从默色(红)开始渐变到红结束
                 */
                function colorBar() {
                    var gradientBar = ctx.createLinearGradient(0, 0, 0, height);
                    gradientBar.addColorStop(0, '#f00');
                    gradientBar.addColorStop(1 / 6, '#f0f');
                    gradientBar.addColorStop(2 / 6, '#00f');
                    gradientBar.addColorStop(3 / 6, '#0ff');
                    gradientBar.addColorStop(4 / 6, '#0f0');
                    gradientBar.addColorStop(5 / 6, '#ff0');
                    gradientBar.addColorStop(1, '#f00');

                    ctx.fillStyle = gradientBar;
                    //绘制出去 x y height width
                    ctx.fillRect(0, 0, 20, height);
                }

                function rgb2hex(rgb) {
                    var aRgb = rgb instanceof Array ? rgb : (rgb.split(',') || [0, 0, 0]);
                    var temp;
                    return [
                        (temp = Number(aRgb[0]).toString(16)).length == 1 ? ('0' + temp) : temp,
                        (temp = Number(aRgb[1]).toString(16)).length == 1 ? ('0' + temp) : temp,
                        (temp = Number(aRgb[2]).toString(16)).length == 1 ? ('0' + temp) : temp,
                    ].join('');
                }

                function hex2rgb(hex) {
                    if (hex.length == 3) {
                        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
                    }
                    return [
                        parseInt(hex[0] + hex[1], 16),
                        parseInt(hex[2] + hex[3], 16),
                        parseInt(hex[4] + hex[5], 16),
                    ].join();
                }

                function putCurDom(color) {
                    if (/([0-9a-f]{3}|[0-9a-f]{6})/i.test(color)) {
                        // hex
                        color = hex2rgb(color);
                    } else if (color instanceof Array) {
                        color = color.join(',');
                    } else if (/\d{1,3}(\,\d{1,3}){2}/i.test(color)) {

                    } else {
                        return;
                    }
                }

                /**
                 *  绘制颜色板块
                 *  addColorStop(stop, color) stop介于0于1之间 表示开始和结束的位置 color在stop的位置需要显示的颜色
                 *  这里画2个渐变对象
                 *      gradientBase从左到右开始渐变
                 *      my_gradient1从上到下开始渐变
                 *  然后让2个重叠在一起 只需要他们的绘画位置不同即可
                 *
                 * @param color 默认颜色(红)
                 */

                function colorBox(color) {
                    //这里x偏30 才能让左上角是白色
                    var gradientBase = ctx.createLinearGradient(30, 0, width + 30, 0);
                    gradientBase.addColorStop(0, 'rgba(255,255,255,1)');
                    gradientBase.addColorStop(1, color);
                    ctx.fillStyle = gradientBase;
                    //画偏30 别挡住颜色条
                    ctx.fillRect(30, 0, width, height);

                    var my_gradient1 = ctx.createLinearGradient(0, 0, 0, width);
                    my_gradient1.addColorStop(0, 'rgba(0,0,0,0)');
                    my_gradient1.addColorStop(1, 'rgba(0,0,0,1)');
                    ctx.fillStyle = my_gradient1;
                    ctx.fillRect(30, 0, width, height);
                }
                //绑定点击事件
                function bind() {
                    var rgbaStr = '#000';
                    can.addEventListener('click', function(e) {
                        var ePos = {
                            x: e.offsetX || e.layerX,
                            y: e.offsetY || e.layerY
                        }
                        if (ePos.x >= 0 && ePos.x < 20 && ePos.y >= 0 && ePos.y < width) {
                            // in
                            rgbaStr = getRgbaAtPoint(ePos, 'bar');
                            colorBox('rgba(' + rgbaStr + ')');
                        } else if (ePos.x >= 30 && ePos.x < 30 + width && ePos.y >= 0 && ePos.y < width) {
                            rgbaStr = getRgbaAtPoint(ePos, 'box');
                        } else {
                            return;
                        }
                        cur.style.left = ePos.x + 'px';
                        cur.style.top = ePos.y + 'px';
                        // cur.style.outlineColor = (rgbaStr[0] > 256 / 2 || rgbaStr[1] > 256 / 2 || rgbaStr[2] > 256 / 2) ? '#000' : '#fff';
                        outColor(rgbaStr);
                    });

                    can.addEventListener("touchmove",function(e){
                        var ePos = {
                            x: parseInt(e.touches[0].pageX),
                            y: parseInt(e.touches[0].pageY-$("#ColorTip").offset().top)
                        };
                        if (ePos.x >= 0 && ePos.x < 20 && ePos.y >= 0 && ePos.y < width) {
                            // in
                            rgbaStr = getRgbaAtPoint(ePos, 'bar');
                            colorBox('rgba(' + rgbaStr + ')');
                        } else if (ePos.x >= 30 && ePos.x < 30 + width && ePos.y >= 0 && ePos.y < width) {
                            rgbaStr = getRgbaAtPoint(ePos, 'box');
                        } else {
                            return;
                        }
                        cur.style.left = ePos.x + 'px';
                        cur.style.top = ePos.y + 'px';
                        // cur.style.outlineColor = (rgbaStr[0] > 256 / 2 || rgbaStr[1] > 256 / 2 || rgbaStr[2] > 256 / 2) ? '#000' : '#fff';
                        outColor(rgbaStr);
                    })
                    can.addEventListener("touchend",function(event){

                    })
                }

                function outColor(rgb) {
                    $timeout(function () {
                        vm.graffitiColor = RGB2Color(rgb[0], rgb[1], rgb[2]);
                    })
                }
                function getRgbaAtPoint(pos, area) {
                    if (area == 'bar') {
                        var imgData = ctx.getImageData(0, 0, 20, width);
                    } else {
                        var imgData = ctx.getImageData(0, 0, can.width, can.height);
                    }

                    var data = imgData.data;
                    var dataIndex = parseInt((pos.y * imgData.width + pos.x) * 4);
                    return [
                        data[dataIndex],
                        data[dataIndex + 1],
                        data[dataIndex + 2],
                        (data[dataIndex + 3] / 255).toFixed(2),
                    ];
                }
            })
        }

        //rgb转16进制
        function RGB2Color(r,g,b) {
            return '#' + byte2Hex(r) + byte2Hex(g) + byte2Hex(b);
        }
        function byte2Hex (n) {
            var nybHexString = "0123456789ABCDEF";
            return String(nybHexString.substr((n >> 4) & 0x0F,1)) + nybHexString.substr(n & 0x0F,1);
        }
        //部件名
        $scope.partName = function (partid) {
            if($scope.template&&$scope.template.config&&$scope.template.config.parts&&$scope.template.config.parts.list){
                var partName = "";
                for(var i=0; i<$scope.template.config.parts.list.length; i++){
                    if($scope.template.config.parts.list[i].id == partid){
                        partName = $scope.template.config.parts.list[i].name;
                    }
                }
                return partName
            }
        }

        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("ws://localhost:8096/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == null) {
                    photo.identifier = $scope.domain + photo.identifier;
                    vm.photoAll.unshift(photo)
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
    }
})();
